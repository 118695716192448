import { combineReducers } from 'redux';

import admin from './admin';
import developer from './developer';
import editDeveloper from './edit-developer';
import editProgramme from './edit-programme';
import session from './session';

const app = combineReducers({
	admin,
	developer,
	editDeveloper,
	editProgramme,
	session,
});

export default app;
