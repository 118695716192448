import {
	SESSION_CLEAR_SESSION_ACTION,
	SESSION_INVALIDATE_SESSION_ACTION,
	SESSION_SET_SESSION_ACTION,
} from '../actions/session';

/*
 * session is null until SET_SESSION or INVALIDATE_SESSION is called.
 * This features allows the session component to prevent redirects while
 * the session has not been verified yet. Once session is not null,
 * redirects to "connection" or "forbidden" pages can occur.
 */
const defaultState = null;

const invalidState = {
	attributes: null,
	roles: null,
	tokens: null,
	valid: false,
};

export default (state = defaultState, action) => {
	switch (action.type) {
	case SESSION_SET_SESSION_ACTION:
		return Object.assign({}, state, { valid: true }, action.session);
	case SESSION_CLEAR_SESSION_ACTION:
		return defaultState;
	case SESSION_INVALIDATE_SESSION_ACTION:
		return Object.assign({}, invalidState);
	default:
		return state;
	}
};
