import { request } from '@cecaz-immo/app-client/dist/lib/request';

import {
	clearSessionAction,
	invalidateSessionAction,
	setSessionAction,
} from '../store/actions/session';
import * as cognito from './cognito';

const { REACT_APP_API_URL } = process.env;

let dispatch = () => {
	throw new Error('store is not connected');
};

const formatSession = (session) => {
	const { cognitoSession } = session;

	return {
		attributes: Object.assign({}, session.cognitoUserAttributes),
		roles: session.decodedIdToken['cognito:roles'],
		tokens: {
			accessToken: cognitoSession.accessToken.jwtToken,
			idToken: cognitoSession.idToken.jwtToken,
			refreshToken: cognitoSession.refreshToken.token,
		},
	};
};

const clearSession = () => dispatch(clearSessionAction());

const invalidateSession = () => dispatch(invalidateSessionAction());

const setSession = () => cognito.getFullSession()
	.then((session) => {
		const formattedSession = formatSession(session);
		return dispatch(setSessionAction(formattedSession));
	});

export const connect = (store) => {
	// console.log('connect');
	dispatch = store.dispatch.bind(store);
	cognito.getSession()
		.then(() => setSession())
		.catch(() => { invalidateSession(); });
};

export const fetchWithSession = (url, options) => cognito.getSession()
	.catch(() => {
		invalidateSession();
		return null;
	})
	.then((session) => {
		if (session) {
			const headers = Object.assign({}, (options && options.headers) || {}, {
				Authorization: session.getIdToken().getJwtToken(),
			});

			options = Object.assign({}, options, { headers });
		}

		return request(url, options);
	});

export const signIn = (username, password) => cognito.signIn(username, password)
	.then(() => setSession());

export const signOut = () => cognito.signOut()
	.then(() => clearSession());

export const completeNewPasswordChallenge = (username, password, newPassword) =>
	cognito.completeNewPasswordChallenge(username, password, newPassword)
		.then(() => setSession());

export const forgotPassword = (username, email, userType) => {
	username = (username || '').toLowerCase();

	userType = userType === 'developer' ? 'developer' : 'admin';

	return fetchWithSession(`${REACT_APP_API_URL}/${userType}/password/reset`, {
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'POST',
		body: JSON.stringify({ username, email }),
	})
		.then((resp) => {
			if (!resp.ok) {
				return Promise.reject(resp);
			}
			return resp;
		})
		.then(res => res.json())
		.catch(res => res.json()
			.then(data => Promise.reject(new Error(), { code: data.error })));
};

export const confirmResetPassword = (username, password, code) =>
	cognito.confirmResetPassword(username, password, code);

export const changePassword = (username, oldPassword, newPassword) =>
	cognito.changePassword(username, oldPassword, newPassword);

export const hasSessionOneOfRoles = (session, roles) => {
	if (!session
		|| !session.roles) return false;
	roles = [].concat(roles);
	const sessionRoles = session.roles;
	const matchingRoles = sessionRoles.filter(role => (roles.includes(role)));
	return matchingRoles.length ? matchingRoles : false;
};

export const hasOneOfRoles = roles => cognito.getSession()
	.then(session => (hasSessionOneOfRoles(session, roles)));
