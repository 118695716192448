import React from 'react';
import PropTypes from 'prop-types';

import './media-item.scss';

const { REACT_APP_AWS_S3_URL } = process.env;

const ProgrammeEditTabsMediaItem = ({ className, file, onClickDelete }) => {
	const src = file.preview || (file.name && (REACT_APP_AWS_S3_URL + file.name));
	let preview;

	if (file.type === 'video/mp4') {
		preview = <video src={src} />;
	} else {
		preview = <img src={src} alt="previsualisation du media" />;
	}

	return (
		<section className={`${className || ''} ProgrammeEditTabsMediaItem`}>
			<header>
				<div>
					<i className="fa fa-arrows icon" />
				</div>
				<span className="title">Principale</span>
				<div
					onClick={() => { onClickDelete(file); }}
					className="ProgrammeEditTabsMediaItem-delete"
				>
					<i className="fa fa-times icon" />
				</div>
			</header>
			<div className={`ProgrammeEditTabsMediaItem-preview ${file.type === 'video/mp4' ? 'ProgrammeEditTabsMediaItem-is-video' : ''}`}>
				{ preview }
				<div className="ProgrammeEditTabsMediaItem-video-overlay"><span className="fa fa-play-circle" /></div>
			</div>
		</section>
	);
};

ProgrammeEditTabsMediaItem.propTypes = {
	className: PropTypes.string,
	onClickDelete: PropTypes.func.isRequired,
	file: PropTypes.shape({
		name: PropTypes.string,
		preview: PropTypes.string,
		type: PropTypes.string,
	}),
};

ProgrammeEditTabsMediaItem.defaultProps = {
	className: '',
	file: {},
};

export default ProgrammeEditTabsMediaItem;
