import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import PageContent from '@cecaz-immo/ui/dist/components/page/content';
import ProgrammeDescription from '@cecaz-immo/app-client/dist/components/programme/programme-description';
import ProgrammeValidationInfo from '@cecaz-immo/ui/dist/components/programme/validation-info';

import { getCurrentProgrammeToEdit } from '../../store/actions/edit-programme';
import { TO_CORRECT } from '../../lib/status';

class Programme extends React.Component {
	constructor(props) {
		super(props);
		const { loadProgrammes } = this.props;

		loadProgrammes();
	}

	render() {
		const { history, programme } = this.props;
		const { commentary, uuid } = programme;

		return (
			<PageContent className="PageProgramme">
				{
					programme.status === TO_CORRECT
					&& (
						<ProgrammeValidationInfo
							commentary={commentary}
							linkToEdit
							uuid={uuid}
						/>
					)
				}
				<ProgrammeDescription
					displayMode
					disableButtonMoreInfos
					history={history}
					programme={programme}
				/>
			</PageContent>
		);
	}
}

Programme.propTypes = {
	history: PropTypes.shape({ push: PropTypes.func }),
	loadProgrammes: PropTypes.func.isRequired,
	programme: PropTypes.shape({
		commentary: PropTypes.string,
		status: PropTypes.string,
		uuid: PropTypes.string,
	}),
};

Programme.defaultProps = {
	history: {},
	programme: {},
};

const mapStateToProps = (state) => ({
	programme: state.editProgramme,
});

const mapDispatchToProps = (dispatch, ownProps) => {
	const { uuid } = ownProps.match.params;
	return {
		loadProgrammes: () => {
			if (!uuid) {
				return Promise.reject();
			}
			return dispatch(getCurrentProgrammeToEdit(uuid));
		},
	};
};

const ProgrammeContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)(Programme);

export default ProgrammeContainer;
