import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { RangePriceDisplay, RangeRoomsDisplay } from '@cecaz-immo/app-client/dist/components/range/range-display';
import Slider from '@cecaz-immo/app-client/dist/components/slider/slider';
import { getFileUrl } from '@cecaz-immo/app-client/dist/lib/file';
import { toInputDateTime } from '@cecaz-immo/ui/dist/lib/date';

import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from '../../lib/roles';
import { WithRole } from '../security/security';

import './programme-list-item.scss';

function getQuarterString(number, small) {
	let dimin;

	switch (number) {
	case 1:
		dimin = 'er';
		break;
	case 2:
		dimin = 'nd';
		break;
	default:
		dimin = small ? 'e' : 'ème';
	}

	const str = small ? 'T' : 'trimestre';

	return `${number}${dimin} ${str}`;
}

const ProgrammeListItem = ({
	Actions, className, editionDetail, Overlay, programme,
}) => (
	<article className={`ProgrammeListItem ${className || ''}`}>
		{ editionDetail
			&& (
				<div className="info">
					<p className="ProgrammeListItem-field">
						<span className="ProgrammeListItem-field-label">Date de création</span>
						<em className="ProgrammeListItem-field-value">{toInputDateTime(programme.createAt)}</em>
					</p>
					<p className="ProgrammeListItem-field">
						<span className="ProgrammeListItem-field-label">Date de la dernière modification</span>
						<em className="ProgrammeListItem-field-value">{toInputDateTime(programme.updateAt)}</em>
					</p>
					{
						programme.publishAt
						&& (
							<p className="ProgrammeListItem-field">
								<span className="ProgrammeListItem-field-label">Date de publication</span>
								<em className="ProgrammeListItem-field-value">{toInputDateTime(programme.publishAt)}</em>
							</p>
						)
					}
				</div>
			)}
		<Slider className="ProgrammeListItem-img">
			{
				programme.files && programme.files.map((file) => (
					<div className="slider-centered-img" key={file.name}>
						<img src={file.preview || getFileUrl(file)} alt={file.name} />
					</div>
				))
			}
		</Slider>

		<div className="ProgrammeListItem-properties">
			<p className="ProgrammeListItem-field">
				<span className="ProgrammeListItem-field-label">Programme</span>
				<em className="ProgrammeListItem-field-value ProgrammeListItem-name">{programme.name}</em>
			</p>

			<p className="ProgrammeListItem-field">
				<span className="ProgrammeListItem-field-label">Localisation</span>
				<em className="ProgrammeListItem-field-value ProgrammeListItem-address">{programme.district}</em>
			</p>

			<p className="ProgrammeListItem-field">
				<span className="ProgrammeListItem-field-label">Prix</span>
				<em className="ProgrammeListItem-field-value">
					<RangePriceDisplay min={programme.minPrice} max={programme.maxPrice} />
				</em>
			</p>

			<p className="ProgrammeListItem-field">
				<span className="ProgrammeListItem-field-label">Pièces</span>
				<em className="ProgrammeListItem-field-value">
					<RangeRoomsDisplay min={programme.minRooms} max={programme.maxRooms} />
				</em>
			</p>

			<p className="ProgrammeListItem-field">
				<span className="ProgrammeListItem-field-label">Livraison</span>
				<em className="ProgrammeListItem-field-value">
					{getQuarterString(programme.quarterDelivery)}
					{' '}
					{programme.yearDelivery}
				</em>
			</p>
		</div>

		<div className="ProgrammeListItem-bottom-right-corner">
			<em className="visible-cols-2 delivery">
				{getQuarterString(programme.quarterDelivery, true)}
				{' '}
				{programme.yearDelivery}
			</em>

			<Link
				to={`/programme/${programme.name}/p/${programme.uuid}`}
				className={`btn ${Actions ? 'btn icon btn-outline-secondary btn-sm' : 'btn-red'}`}
				title="Voir le programme"
			>
				{ !Actions && <span>Voir le programme</span>}
				<i className="ic ic-voir" />
			</Link>

			{ Actions }
		</div>
		<WithRole role={ROLE_ADMIN}>
			<div className="info info-admin">
				<p className="ProgrammeListItem-field">
					<span className="ProgrammeListItem-field-label">Version</span>
					<em className="ProgrammeListItem-field-value">
						{programme.version}
					</em>
				</p>
				<WithRole role={ROLE_SUPER_ADMIN}>
					<p className="ProgrammeListItem-field">
						<span className="ProgrammeListItem-field-label">Admininistrateur</span>
						<em className="ProgrammeListItem-field-value">
							{programme.developer && programme.developer.admin
								&& `${programme.developer.admin.lastName} ${programme.developer.admin.firstName}`}
						</em>
					</p>
				</WithRole>
				<p className="ProgrammeListItem-field">
					<span className="ProgrammeListItem-field-label">Promoteur</span>
					<em className="ProgrammeListItem-field-value">
						{programme.developer && `${programme.developer.lastName} ${programme.developer.firstName}`}
					</em>
				</p>
			</div>
		</WithRole>
		{ Overlay }
	</article>
);

ProgrammeListItem.propTypes = {
	Actions: PropTypes.node,
	className: PropTypes.string,
	editionDetail: PropTypes.bool,
	Overlay: PropTypes.node,
	programme: PropTypes.shape({
		developer: PropTypes.shape({
			admin: PropTypes.shape({
				firstName: PropTypes.string,
				lastName: PropTypes.string,
			}),
			firstName: PropTypes.string,
			lastName: PropTypes.string,
		}),
		createAt: PropTypes.string,
		files: PropTypes.arrayOf(PropTypes.shape({})),
		updateAt: PropTypes.string,
		name: PropTypes.string,
		uuid: PropTypes.string,
		publishAt: PropTypes.string,
		district: PropTypes.string,
		minPrice: PropTypes.number,
		maxPrice: PropTypes.number,
		minRooms: PropTypes.number,
		maxRooms: PropTypes.number,
		quarterDelivery: PropTypes.number,
		version: PropTypes.number,
		yearDelivery: PropTypes.string,
	}).isRequired,
};

ProgrammeListItem.defaultProps = {
	Actions: null,
	className: '',
	editionDetail: false,
	Overlay: null,
};

export default ProgrammeListItem;
