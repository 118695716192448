import React from 'react';

import PageContent from '@cecaz-immo/ui/dist/components/page/content';

import Help from '../components/help/help';

export default () => (
	<PageContent className="PageHelp">
		<section className="page-center section last-section">
			<Help />
		</section>
	</PageContent>
);
