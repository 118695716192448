import { password } from './types';

const forgotPasswordSchema = {
	type: 'object',
	properties: {
		email: { type: 'string', format: 'email', maxLength: 255 },
	},
	required: [
		'email',
	],
};

const resetPasswordSchema = {
	additionalProperties: false,
	type: 'object',
	properties: {
		password,
		passwordConfirm: { type: 'string', const: { $data: '1/password' } },
	},
	required: [
		'password',
		'passwordConfirm',
	],
};

const resetPasswordWithCodeSchema = {
	additionalProperties: false,
	type: 'object',
	properties: {
		...resetPasswordSchema.properties,
		code: { type: 'string' },
	},
	required: [
		...resetPasswordSchema.required,
		'code',
	],
};

const signInSchema = {
	type: 'object',
	properties: {
		username: { type: 'string', format: 'email', maxLength: 255 },
		password: { type: 'string' },
	},
	required: [
		'username',
		'password',
	],
};

export {
	forgotPasswordSchema,
	resetPasswordSchema,
	resetPasswordWithCodeSchema,
	signInSchema,
};
