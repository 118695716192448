import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// components
import FieldAdmin from '@cecaz-immo/ui/dist/components/form/field/admin';
import ButtonLoading from '@cecaz-immo/ui/dist/components/button/Loading';
import AlertMessage from '../../alert-message/alert-message';
import AuthForm from '../form/form';

// schemas
import { signInSchema } from '../../../schemas/auth';
// libs
import { signIn } from '../../../lib/session';

class AuthFormsSignIn extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			display: false,
			title: '',
			message: '',
			success: false,
			username: '',
			password: '',
		};

		this.onSubmitConnection = this.onSubmitConnection.bind(this);
		this.onChangeInput = this.onChangeInput.bind(this);
	}

	onChangeInput(data) {
		this.setState(data);
	}

	onSubmitConnection(e) {
		e.preventDefault();

		const { history } = this.props;
		const { password, username } = this.state;

		this.setState({ loading: true });
		signIn(username, password)
			.then(() => { history.push('/'); })
			.catch((err) => {
				let title = '';
				let message = '';
				console.error(err);
				global.rollbar.error('error onSubmitConnection', err);


				if (err.code === 'NotAuthorizedException') {
					title = 'Erreur identifiant / mot de passe';
					message = 'Les identifiants saisis sont incorrects.';
				} else if (err.code === 'UserNotFoundException') {
					title = 'Erreur identifiant';
					message = `L'utilisateur ${username} n'existe pas.`;
				} else if (err.code === 'PasswordResetRequiredException') {
					title = 'Erreur identifiant';
					message = 'Vous devez réinitilisater votre mot de passe';
				} else {
					title = 'Erreur système';
					message = 'Veuillez contacter un administrateur'
						+ ' pour obtenir plus de renseignements.';
				}

				this.setState({
					display: true,
					title,
					message,
					loading: false,
					success: false,
					username: '',
					password: '',
				});
			});
	}

	render() {
		const {
			display,
			loading,
			message,
			password,
			success,
			title,
			username,
		} = this.state;

		return (
			<AuthForm
				data={this.state}
				schema={signInSchema}
				onChange={this.onChangeInput}
				onSubmit={this.onSubmitConnection}
			>
				<h3 className="title">Se connecter</h3>
				<div className="row">
					<FieldAdmin
						component="input"
						type="text"
						name="username"
						value={username}
						className="field"
						placeholder="Votre email"
					/>
				</div>
				<div className="row">
					<FieldAdmin
						component="input"
						type="password"
						name="password"
						value={password}
						className="field"
						placeholder="Votre mot de passe"
					/>
				</div>
				<ButtonLoading
					outline
					color="primary"
					loading={loading}
					type="submit"
				>
					Envoyer
				</ButtonLoading>
				<div className="row">
					<Link to="/auth/forgot-password/">Mot de passe oublié ?</Link>
				</div>
				<AlertMessage
					display={display}
					title={title}
					message={message}
					success={success}
				/>
			</AuthForm>
		);
	}
}

AuthFormsSignIn.propTypes = {
	history: PropTypes.shape({ push: PropTypes.func }),
};

AuthFormsSignIn.defaultProps = {
	history: {},
};

const mapStateToProps = (/* state */) => ({});

export default connect(mapStateToProps)(AuthFormsSignIn);
