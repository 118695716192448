import InputMask from 'react-input-mask';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	Alert,
	Button,
	Card,
	CardHeader,
	CardBody,
	Col,
	CustomInput,
	FormGroup,
	Input,
	Label,
	Row,
} from 'reactstrap';

import { toInputDate, toISO } from '@cecaz-immo/ui/dist/lib/date';

import CustomMap from '@cecaz-immo/app-client/dist/components/map/map';
import Marker from '@cecaz-immo/app-client/dist/components/map/marker';
import { locationToGeometryBounds } from '@cecaz-immo/app-client/dist/lib/location';
import { Field, FieldError } from 'react-jsonschema-form-validation';

import Select from '@cecaz-immo/ui/dist/components/form/select/Simple';

import './general.scss';

const YEAR_NUMBER = 10;
const calculateYearsOptions = () => {
	const result = [];
	const date = new Date('01/01/2018');
	result.push({
		value: `${date.getFullYear()}`,
		label: date.getFullYear(),
	});

	for (let i = 0; i < YEAR_NUMBER; i += 1) {
		date.setFullYear(date.getFullYear() + 1);
		result.push({
			value: `${date.getFullYear()}`,
			label: date.getFullYear(),
		});
	}

	return result;
};

const QUARTER_NUMBER = 4;
const calculateQuartersOptions = (() => {
	const result = [];
	for (let i = 1; i <= QUARTER_NUMBER; i += 1) {
		result.push({
			value: i,
			label: `Trimestre ${i}`,
		});
	}
	return result;
})();

const InputDate = ({ ...props }) => {
	// eslint-disable-next-line
	delete props.errorMessages;
	return (
		<InputMask
			{...props}
			mask="99/99/9999"
			maskChar="_"
		>
			{(inputProps) => <Input {...inputProps} />}
		</InputMask>
	);
};

const defaultLocation = {
	bounds: {
		north: 43.760905,
		south: 43.6454759,
		east: 7.323481099999999,
		west: 7.182153,
	},
};

const ProgrammeEditGeneral = ({
	className, isNew, programme, updateCurrentProgramme,
}) => {
	const [showErrorLocation, setShowErrorLocation] = useState(false);
	const [location, setLocation] = useState(null);
	const [formData, setFormData] = useState({
		date: '',
		address: '',
		description: '',
		district: '',
		floors: '',
		name: '',
		pinelLaw: false,
		quarterDelivery: null,
		reducedVat: false,
		yearDelivery: '',
	});

	useEffect(() => {
		if (isNew) {
			setFormData((prev) => ({ ...prev, date: '' }));
		}
	}, [isNew]);

	useEffect(() => {
		if (programme) {
			setFormData((prev) => ({
				address: programme.address || '',
				date: programme.saleStartDate ? toInputDate(programme.saleStartDate) : prev.date,
				description: programme.description || '',
				district: programme.district || '',
				floors: programme.floors || '',
				name: programme.name || '',
				pinelLaw: programme.pinelLaw || false,
				quarterDelivery: programme.quarterDelivery || '',
				reducedVat: programme.reducedVat || false,
				yearDelivery: programme.yearDelivery || '',
			}));
		}
	}, [programme, setFormData]);


	const handleDateChange = (e) => {
		const dateRegex = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
		const { value } = e.target;
		setFormData((prev) => ({ ...prev, date: value }));
		if (value.match(dateRegex)) {
			updateCurrentProgramme({
				saleStartDate: toISO(value),
			});
		} else {
			updateCurrentProgramme({
				saleStartDate: null,
			});
		}
	};

	const handleSelectChange = (data) => {
		const { name, value } = data.target;
		updateCurrentProgramme({
			[name]: value.value,
		});
	};

	const handleClickCheckAddress = () => {
		setShowErrorLocation(false);

		if (!programme.address) {
			setShowErrorLocation(true);
			return;
		}

		locationToGeometryBounds(programme.address).then((resp) => {
			if (resp && resp.viewport && resp.viewport) {
				updateCurrentProgramme({
					lat: resp.location.lat,
					lng: resp.location.lng,
				});
				setLocation({
					bounds: {
						north: resp.viewport.northeast.lat,
						south: resp.viewport.southwest.lat,
						east: resp.viewport.northeast.lng,
						west: resp.viewport.southwest.lng,
					},
				});
			} else {
				setLocation(null);
				setShowErrorLocation(true);
			}
		});
	};
	return (
		<Card className={clsx(className, 'ProgrammeEditGeneral flex-column')}>
			<CardHeader>
				<h2>Informations</h2>
			</CardHeader>
			<CardBody>
				<Row>
					<Col xs="12" md="6" className="d-flex flex-column">
						<FormGroup>
							<Label>Nom du programme</Label>
							<Field
								className=""
								component={Input}
								type="text"
								name="name"
								placeholder="Nom du programme"
								value={formData.name}
							/>
							<FieldError name="name" />
						</FormGroup>
						<FormGroup>
							<Label>Adresse du programme</Label>
							<Field
								className="ProgrammeEditGeneral addrInput"
								component={Input}
								type="text"
								name="address"
								placeholder="Adresse du programme"
								value={formData.address}
							/>
							<FieldError name="address" />
							<div className="d-flex align-items-start justify-content-start mt-2">
								<Button
									outline
									size="sm"
									onClick={handleClickCheckAddress}
								>
									Vérifier
								</Button>
								{
									showErrorLocation && (
										<Alert
											color="danger"
											className="ml-2 mb-0 sm"
										>
											Adresse introuvable !
										</Alert>
									)
								}
							</div>

							{
								programme.address
									&& (
										<FieldError
											className="errorCoords"
											name={['lat', 'lng']}
											errorMessages={{
												required: () => 'Vérifiez l\'adresse',
											}}
										/>
									)
							}

						</FormGroup>
						<FormGroup>
							<Label>Quartier du programme</Label>
							<Field
								className=""
								component={Input}
								type="text"
								name="district"
								placeholder="Quartier du programme"
								value={formData.district}
							/>
							<FieldError name="district" />
						</FormGroup>
					</Col>
					<Col xs="12" md="6" className="d-flex flex-column">
						<FormGroup>
							<Label>Nomdre d&apos;étage</Label>
							<Field
								className=""
								component={Input}
								type="number"
								name="floors"
								placeholder="étage"
								value={formData.floors}
								min="0"
							/>
							<FieldError name="floors" />
						</FormGroup>
						<FormGroup>
							<Label>Date de commercialisation</Label>
							<Field
								className="inputDate"
								component={InputDate}
								mask="99/99/9999"
								type="text"
								name="saleStartDate"
								placeholder="jj/mm/aaaa"
								value={formData.date}
								onChange={handleDateChange}
								errorMessages={{
									pattern: () => `Error ${formData.date}`,
								}}
							/>
							<FieldError name="saleStartDate" />
						</FormGroup>
						<FormGroup className="d-flex flex-row align-items-center">
							<Field
								component={CustomInput}
								className="danger"
								id="reducedVat"
								name="reducedVat"
								type="checkbox"
								checked={formData.reducedVat}
							/>
							<Label htmlFor="reducedVat">TVA Réduite</Label>
						</FormGroup>

						<FormGroup className="d-flex flex-row align-items-center">
							<Field
								component={CustomInput}
								id="pinelLaw"
								name="pinelLaw"
								type="checkbox"
								checked={formData.pinelLaw}
							/>
							<Label htmlFor="pinelLaw">Loi Pinel</Label>
						</FormGroup>
					</Col>
					{ location && (
						<Col xs="12">
							<CustomMap
								zoom={13}
								bounds={location && location.bounds ? location.bounds : defaultLocation.bounds}
								className="ProgrammeEditGeneral CustomMap"
							>
								{
									location && location.bounds && (
										<Marker
											position={{
												lat: location.bounds.north,
												lng: location.bounds.east,
											}}
											key={programme.id}
										/>
									)
								}
							</CustomMap>
						</Col>
					)}
				</Row>
				<Row>
					<Col xs="12">
						<h3>Date de livraison prévue</h3>
					</Col>
					<Col xs="12" className="d-flex flex-column flex-md-row">
						<FormGroup
							className="d-flex flex-column align-items-start mr-0 mr-sm-4"
						>
							<Label>Années</Label>
							<Field
								component={Select}
								name="yearDelivery"
								value={formData.yearDelivery}
								options={calculateYearsOptions()}
								className="select"
								onChange={handleSelectChange}
							/>
							<FieldError name="yearDelivery" />
						</FormGroup>
						<FormGroup
							className="d-flex flex-column align-items-start"
						>
							<Label>Trimestre</Label>
							<Field
								component={Select}
								name="quarterDelivery"
								value={formData.quarterDelivery}
								className="select"
								options={calculateQuartersOptions}
								onChange={handleSelectChange}
							/>
							<FieldError name="quarterDelivery" />
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col xs="12">
						<h3>Description</h3>
						<i>2 000 caractères maximum autorisés</i>
					</Col>
					<Col xs="12">
						<FormGroup className="d-flex flex-column">
							<Field
								className="description"
								component={Input}
								name="description"
								rows={8}
								type="textarea"
								value={formData.description}
							/>
							<FieldError name="description" />
						</FormGroup>
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
};

ProgrammeEditGeneral.propTypes = {
	className: PropTypes.string,
	isNew: PropTypes.bool,
	programme: PropTypes.shape({
		address: PropTypes.string,
		description: PropTypes.string,
		district: PropTypes.string,
		floors: PropTypes.number,
		id: PropTypes.number,
		name: PropTypes.string,
		pinelLaw: PropTypes.bool,
		quarterDelivery: PropTypes.number,
		reducedVat: PropTypes.bool,
		saleStartDate: PropTypes.string,
		yearDelivery: PropTypes.string,
	}),
	updateCurrentProgramme: PropTypes.func.isRequired,
};

ProgrammeEditGeneral.defaultProps = {
	className: '',
	isNew: false,
	programme: {},
};

export default React.memo(ProgrammeEditGeneral);
