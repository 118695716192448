import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import PageContent from '@cecaz-immo/ui/dist/components/page/content';
import Select from '@cecaz-immo/ui/dist/components/form/select/Simple';
import getInputValue from '@cecaz-immo/ui/dist/lib/input-value';

import * as Status from '../../lib/status';
import Actions from '../../components/actions/actions';
import ProgrammeEditList from '../../components/programme/edit/list/list';
import { getCurrentDeveloperAction } from '../../store/actions/developer';
import { select } from '../../store/selectors/developer';

const Home = () => {
	const dispatch = useDispatch();
	const [status, setStatus] = useState('');
	const [lastProgrammes, setLastProgrammes] = useState([]);
	const currentDeveloper = useSelector(select);
	useEffect(() => {
		dispatch(getCurrentDeveloperAction());
	}, [dispatch]);

	useEffect(() => {
		if (currentDeveloper) {
			setLastProgrammes(currentDeveloper.programmes || []);
		}
	}, [currentDeveloper]);


	const onChangeStatus = (s) => {
		if (!s.value) setStatus('');
		else {
			setStatus(getInputValue(s.value) || '');
		}
	};

	return (
		<PageContent className="PageHome">
			<Actions>
				<Link to="/programme/edit" className="btn btn-outline-primary">
					Créer un programme <i className="fa fa-plus-circle" />
				</Link>
			</Actions>
			<section className="section last-section">
				<header>
					<h1 className="section-title">Mes programmes immobiliers</h1>
				</header>
				<div className="page-center">
					<Select
						name="status"
						onChange={({ target }) => onChangeStatus(target)}
						isClearable
						options={[
							{ value: Status.TO_CORRECT, label: Status.labels[Status.TO_CORRECT] },
							{ value: Status.WAITING, label: Status.labels[Status.WAITING] },
							{ value: Status.PUBLISHED, label: Status.labels[Status.PUBLISHED] },
							{ value: Status.FINISHED, label: Status.labels[Status.FINISHED] },
						]}
						value={status}
					/>
					<ProgrammeEditList status={status} displayEditButton programmes={lastProgrammes} />
				</div>
			</section>
		</PageContent>
	);
};


export default Home;
