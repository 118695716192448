import React from 'react';

import LegalArticle from '@cecaz-immo/ui/dist/components/legal/article';
import LegalSection from '@cecaz-immo/ui/dist/components/legal/section';

const { REACT_APP_WWW } = process.env;

/* eslint-disable react/jsx-one-expression-per-line*/
export default () => (
	<LegalSection>
		<h1 className="section-title">Charte technique d’utilisation</h1>
		<LegalArticle>
			<h2>1 Accès à son espace</h2>
			<p>Après la signature de la convention de Partenariat par le Promoteur,
					l’administrateur crée son espace du site internet {REACT_APP_WWW},
					en renseignant les informations suivantes:
			</p>
			<ul>
				<li>La raison sociale</li>
				<li>Le nom et prénom</li>
				<li>L’adresse mail</li>
				<li>Le numéro de téléphone</li>
			</ul>
			<p>
				Le Promoteur reçoit un mail, avec un lien pour confirmer et
				finaliser son inscription, en personnalisant son mot de passe.
			</p>
			<p>Voir les définitions des CGU Promoteur</p>
			<ul>
				<li>
					Identifiant : désigne l’adresse de courrier électronique renseignée par le Promoteur,
					lors de la phase d’inscription, si et sur laquelle lui sera transmis le courrier
					électronique de confirmation d’inscription. Cette adresse servira également à
					recevoir les notifications.
				</li>
				<li>
					Mot de passe : désigne le code choisi par le Promoteur, exclusif et confidentiel,
					d’une longueur de huit (8) caractères alphanumériques et/ou spéciaux, avec trois (3)
					caractères différents minimum (majuscule, minuscule, chiffre et caractère spécial).
				</li>
				<li>
					Gestion des accès : le promoteur peut avoir plusieurs accès à l’espace promoteur,
					en faisant la demande à son conseiller Caisse d’Epargne Côte d’Azur.
				</li>
			</ul>
		</LegalArticle>
		<LegalArticle>
			<h2>2 Publication d’un programme</h2>
			<p>
				Pour créer un programme, le Promoteur doit renseigner des informations
				sur le programme:
			</p>
			<ul>
				<li>Nom du programme</li>
				<li><strong>Adresse du programme</strong>:
						permet uniquement de géolocaliser le programme
				</li>
				<li>Quartier du programme</li>
				<li>Nombre d’étage</li>
				<li>Date de commercialisation</li>
				<li>Date de livraison prévue</li>
				<li>
					<strong>Description</strong>:
						le contenu publié par le promoteur ne doit pas contenir :
					<ul>
						<li>Le nom du promoteur</li>
						<li>Le numéro de téléphone du promoteur</li>
						<li>L’adresse du promoteur</li>
						<li>Adresse mail du promoteur</li>
					</ul>
				</li>
				<li>
					<strong>Visuel (médias)</strong>: le promoteur peut publier :
					<ul>
						<li>Des photos (images)</li>
						<li>Des plans (2D et 3D)</li>
						<li>Des vidéos</li>
					</ul>
				</li>
				<li>Lots: des informations sur les lots (nombre de pièces,
						surface, prix, parking..)
				</li>
			</ul>
			<p>
				L’administrateur peut décider de publier le programme ou de le refuser en ajoutant
				un commentaire afin d’aider le promoteur à effectuer les corrections nécessaires
				pour que l’annonce soit conforme aux normes convenus à la présente charte
				technique d’utilisation.
			</p>
			<p>
				Le promoteur reçoit une notification par mail si le programme est publié ou refusé.
			</p>
			<p>
				Dans le cas de l’édition d’un programme déjà publié, l’administrateur Caisse
				d’Epargne Côte d’Azur reçoit une notification de modification d’un programme. Il peut
				alors valider ou refuser les modifications comme pour la publication d’un nouveau
				programme.
			</p>
		</LegalArticle>
		<LegalArticle>
			<h2>3 Maintenance du site</h2>
			<ul>
				<li>
					La maintenance du site est assurée par <a href="https://www.53js.fr/">53JS</a> prestataire
					de services de la Caisse d’Epargne Côte d’Azur.
				</li>
				<li>
					Pour toutes informations concernant l’utilisation du service (espace personnel),
					le promoteur peut contacter l’administrateur Caisse d’Epargne Côte d’Azur
					(contact disponible sur l’espace promoteur).
				</li>
			</ul>
		</LegalArticle>
		<LegalArticle>
			<h2>4 Notifications</h2>
			<ul>
				<li>
					<h3>Mot de passe oublié</h3>
					<p>
						Cette notification est envoyée par mail à un utilisateur inscrit lorsqu’il a
						demandé la saisie d’un nouveau mot de passe dans la page mot de passe oublié.
					</p>
					<p>Destinataires : administrateur / promoteur</p>
				</li>
				<li>
					<h3>Invitation</h3>
					<p>
						Cette notification est envoyée par mail au promoteur qui vient d’être invité
						par un administrateur.
					</p>
					<p>Destinataires : promoteur</p>
				</li>
				<li>
					<h3>Programme publié</h3>
					<p>
						Cette notification est envoyée par mail au promoteur dont le programme en attente de
						validation a été validé par son administrateur
					</p>
					<p>Destinataires : promoteur</p>
				</li>
				<li>
					<h3>Programme refusé</h3>
					<p>
						Cette notification est envoyée par mail au promoteur dont le programme en attente de
						validation a été refusé par son administrateur
					</p>
					<p>Destinataires : promoteur</p>
				</li>
				<li>
					<h3>Nouveau programme</h3>
					<p>
						Cette notification est envoyée par mail à un administrateur lorsqu’un de ses
						promoteurs a ajouté ou modifié un programme
					</p>
					<p>Destinataires : administrateur</p>
				</li>
			</ul>
		</LegalArticle>
		<LegalArticle>
			<h2>5 Résiliation suspension ou suppression de l’accès à l’espace personnel</h2>
			<p>
				Le Promoteur pourra résilier son Espace en envoyant un courrier électronique à la
				Caisse d’Epargne Côte d’Azur. Le Promoteur devra préalablement sauvegarder et supprimer
				les Programmes ces derniers étant accessibles pendant 4 semaines à compter de l’envoi
				de ce mail.
			</p>
			<p>Le promoteur reçoit une confirmation par mail.</p>
		</LegalArticle>
	</LegalSection>
);
