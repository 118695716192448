import PropTypes from 'prop-types';
import React from 'react';

import LegalArticle from '@cecaz-immo/ui/dist/components/legal/article';
import LegalSection from '@cecaz-immo/ui/dist/components/legal/section';

const LegalCGU = ({
	appName,
	appPublicEmail,
	appUrl,
	appWWW,
}) => (
	<section className="page-center section last-section">
		<LegalSection>
			<span className="text-center">{appWWW}</span>
			<h1 className="section-title">Conditions générales d&apos;utilisation «Espace Promoteur»</h1>
			<LegalArticle>
				<h2>Préambule</h2>
				<p>
					Les présentes conditions générales d&apos;utilisation de l’Espace Promoteur ont
					pour objet l&apos;encadrement juridique des modalités de mise à disposition des
					services du Site <a href={appUrl}>{appWWW}</a> et
					leur utilisation par le Promoteur.
				</p>
				<p>
					Ces Conditions Générales peuvent être modifiées à tout moment par la Caisse
					d’Epargne Côte d’Azur et entrent en vigueur à compter de leur mise en ligne sur le Site.
				</p>
				<p>
					Les Conditions Générales applicables sont celles en vigueur à la date de
					l’inscription sur le Site.
				</p>
				<p>
					Les Conditions Générales s’appliquent à tout accès et toute consultation du
					Site par un Promoteur ainsi qu’à toute utilisation du Site par un Promoteur.
					L’acceptation des Conditions Générales par tout Promoteur est un préalable indispensable
					et obligatoire. Cette acceptation résulte pour le Promoteur, de l&apos;accès au
					Site et de son clic d’acceptation sur la case figurant en regard de la mention
					&quot;J&apos;accepte les conditions générales d&apos;utilisation&quot;.
				</p>
				<p>
					Le Promoteur reconnaît expressément qu’il a pris connaissance et accepté les
					présentes Conditions générales.
				</p>
				<p>
					La Caisse d’Epargne Côte d’Azur se réserve le droit d’apporter des modifications
					aux Conditions Générales, à l’Espace Promoteur. Toute modification entre en vigueur
					à compter de sa date de mise en ligne, ou le cas échéant à toute autre date qui
					serait indiquée dans toute condition particulière applicable au service. Aussi,
					tout Promoteur doit régulièrement se rendre dans son Espace et vérifier
					l’existence de telles modifications. Tout Promoteur est réputé avoir accepté
					la nouvelle version des Conditions Générales, du Site et/ou des services du simple
					fait de l’accès au Site et/ou de la poursuite de l’utilisation des services).
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 1 - Définitions</h2>
				<ul>
					<li>
						<strong>Conseiller Caisse d’Epargne Côte d’Azur</strong> : désigne
						le conseiller de la Caisse
						d’Epargne Côte d’Azur contacté par les Utilisateurs par rapport à un bien
						immobilier du Promoteur figurant sur son Espace.
					</li>
					<li>
						<strong>Conseiller promoteur</strong> : désigne la personne mandatée par le Promoteur
						en charge de la commercialisation du Programme.
					</li>
					<li>
						<strong>Donnée personnelle</strong> : désigne  toute information se rapportant
						à une personne physique identifiée ou identifiable.
					</li>
					<li>
						<strong>Espace Promoteur (ou Espace)</strong> : désigne l’espace réservé au Promoteur.
						Le Promoteur y accède au moyen d’une procédure d’authentification,
						y effectue toutes les opérations autorisées sur le Site et accède à
						ses informations personnelles.
					</li>
					<li>
						<strong>Identifiant</strong> : désigne l’adresse de
						courrier électronique renseignée par
						le Promoteur, lors de la phase d’inscription, si et sur laquelle lui sera
						transmis le courrier électronique de confirmation d’inscription. Cette adresse
						servira également aux notifications des Promoteurs
					</li>
					<li>
						<strong>Mot de passe</strong> : désigne le code choisi par le Promoteur,
						exclusif et confidentiel,
						d’une longueur d’une longueur de huit (8) caractères alphanumériques et/ou spéciaux,
						avec trois (3) caractères différents minimum (majuscule, minuscule, chiffre et
						caractère spécial) : Exemple (Ami2017$) qui, associé à son Identifiant, est demandé
						au Promoteur pour accéder à son Espace.
					</li>
					<li>
						<strong>Gestion des accès</strong> : le promoteur peut avoir
						plusieurs accès à l’espace
						promoteur, en faisant la demande à son conseiller Caisse d’Epargne Côte d’Azur.
					</li>
					<li>
						<strong>Mise en relation</strong> : désigne le formulaire émis par
						un Utilisateur et transmis
						au Conseiller Promoteur et au Conseiller Caisse d’Epargne Côte d’Azur ;
					</li>
					<li>
						<strong>Programme immobilier (ou Programme)</strong> : désigne tout
						programme immobilier du Promoteur,
						et dont un descriptif créé par le Promoteur ou par la Caisse d’Epargne Côte d’Azur
						mais validé par le Promoteur, figurant sur le Site.
					</li>
					<li>
						<strong>Promoteur</strong> : tout professionnel dont la mission consiste
						à vendre des espaces
						construits ou prêts à construire financés par la Caisse d’Epargne Côte d’Azur
						qui utilise {appName} ou l&apos;un des services proposés par {appName}.
					</li>
					<li>
						<strong>Service</strong> : désigne le service proposé par la Caisse d’Epargne
						Côte d’Azur et décrit
						à l’article 3 des présentes.
					</li>
					<li>
						<strong>Site</strong> : désigne le site internet propriété de
						la Caisse d’Epargne Côte d’Azur à partir
						duquel le Promoteur accède à l’Espace Promoteur, accessible notamment depuis
						l’adresse <a href={appUrl}>{appWWW}</a>.
					</li>
					<li>
						<strong>Utilisateur</strong> : désigne toute personne qui navigue sur le
						Site sans être un Promoteur.
					</li>
				</ul>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 2 - Objet de l’Espace Promoteur</h2>
				<p>
					La Caisse d’Epargne Côte d’Azur met à disposition de ses Promoteurs un Site permettant
					de mettre en relations les Utilisateurs avec les Promoteurs ainsi que de réaliser les
					fonctionnalités figurant à l’article 3 des présentes.
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 3 – Description du Service</h2>
				<p>
					L’utilisation de l’Espace Promoteur est réservée aux Promoteurs. La Caisse d’Epargne
					Côte d’Azur propose au Promoteur, un Espace lui permettant de bénéficier de différents
					services tels que :
				</p>
				<ul>
					<li>Gérer son profil en complétant ses informations personnelles</li>
					<li>Valider la création d’un Programme immobilier</li>
					<li>Gérer les Programmes immobiliers</li>
					<li>
						Favoriser la mise en contact des Utilisateurs et des Conseillers Caisse
						d’Epargne Côte d’Azur
					</li>
				</ul>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 4 - Accès au Site</h2>
				<p>L’inscription est gratuite, elle se fait à partir du Site.</p>
				<p>
					Lors de son inscription, le Promoteur doit fournir à la Caisse
					d’Epargne Côte d’Azur certaines informations :
				</p>
				<ul>
					<li>son nom et prénom</li>
					<li>son numéro de téléphone</li>
					<li>son adresse mail</li>
				</ul>
				<p>
					Il est expressément convenu que le Promoteur n’autorise pas une autre personne de
					créer un Espace en son nom pour son usage ou pour son bénéfice.
				</p>
				<p>
					Le Promoteur garantit que les informations qu’il transmet sont exactes, sincères et
					à jour et il s’engage à les mettre à jour dès que nécessaire.
					Si ces informations devaient
					s’avérer fausses, incomplètes ou obsolètes, la caisse d’Epargne Côte d’Azur se réserve
					le droit d’interrompre la fourniture du Service conformément aux stipulations
					de l’article
					« Résiliation - Manquement – Suspension ».
				</p>
				<p>
					Le Promoteur est seul responsable des conséquences de l&apos;absence
					d&apos;actualisation de ses informations personnelles et reconnaît expressément que
					la responsabilité du Promoteur ne
					saurait être engagée en cas de déclarations mensongères concernant ses informations
					personnelles.
				</p>
				<p>
					En tout état de cause, les informations le concernant seront traitées conformément
					aux exigences de la réglementation applicable en matière de protection des données à
					caractère personnel. Il reconnaît que les données recueillies seront utilisées à des
					fins de bon fonctionnement du Service et à des fins statistiques.
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 5 – Prérequis</h2>
				<p>
					Le Promoteur reconnaît de disposer de la compétence et des moyens nécessaires pour
					accéder et utiliser ce Site.
				</p>
				<p>
					Les équipements (matériels ou logiciels) nécessaires à l’accès au Service ainsi que
					tous les autres frais afférents, y compris les frais de télécommunication sont à la
					charge exclusive du Promoteur. Le Promoteur est seul responsable du matériel utilisé
					dans le cadre du Service.
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 6 – Mise en relation avec l’Utilisateur</h2>
				<p>
					La Caisse d’Epargne Côte d’Azur met en relation les Utilisateurs et les Promoteurs
					par le biais de son Site. Pour chaque Mise en relation, un Conseiller de la Caisse
					d’Epargne Côte d’Azur est informé par courrier électronique.
				</p>
				<p>
					Le Promoteur a l’obligation d’informer la Caisse d’Epargne Côte d’Azur du suivi de
					la vente, trente (30) jours après la mise en relation.
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 7 – Obligations du Promoteur</h2>
				<p>
					Le Promoteur est responsable de l’utilisation de son Espace Promoteur ainsi que de
					l&apos;utilisation de ses éléments d’Identification.
				</p>
				<p>
					Le Promoteur reconnaît que toute utilisation de son Espace Promoteur avec ses éléments
					d’identification est présumée faite par le Promoteur et lui sera imputée, à charge
					pour le Promoteur d’apporter la preuve contraire.
				</p>
				<p>
					Le Promoteur s’engage à compléter et à valider le Programme immobilier en renseignant
					les éléments suivants :
				</p>
				<ul>
					<li>La date prévisible d’achèvement</li>
					<li>La date de fin de commercialisation</li>
					<li>Le nombre de </li>
					<li>Le descriptif détaillé pour chaque lot</li>
					<li>Le prix</li>
				</ul>
				<p>
					Le Promoteur dispose de la faculté de modifier les descriptifs et les images du
					Programme immobilier et d’associer le plan correspondant à chaque niveau.
				</p>
				<p>
					Le Promoteur garantit que les contenus des Programmes immobiliers sont conformes
					à la législation, notamment qu’ils n’ont pas trait à des activités constituant
					un acte de contrefaçon, de concurrence déloyale ou de parasitisme, tout type
					d’escroquerie ou de comportement illicite, d’usurpation d’identité.
				</p>
				<p>
					Le Promoteur garantit qu’il possède la totalité des droits de propriété intellectuelle
					et industrielle sur tous les contenus des Programmes immobiliers fournis à la Caisse
					d’Epargne Côte d’Azur. Le Promoteur garantit la Caisse d’Epargne Côte d’Azur contre
					toute action, revendication ou opposition qui serait engagée à son encontre de la
					part de toute personne au motif notamment que les contenus des Programmes immobiliers
					constituent une contrefaçon de droits préexistants de propriété intellectuelle
					revendiqués par des tiers, ou un acte de concurrence déloyale et/ou parasitaire auquel
					l’exécution des CGU aurait porté atteinte. En particulier, il sera seul responsable de
					tout acte de contrefaçon, de parasitisme ou toute violation de droits de propriété
					intellectuelle et plus largement de tout préjudice direct ou indirect matériel ou
					corporel causé par l’utilisation des Programmes immobiliers disponibles via le Service.
				</p>
				<p>
					Dans l’hypothèse où la responsabilité de la Caisse d’Epargne Côte
					d’Azur serait néanmoins
					retenue par une autorité judiciaire ou administrative,
					le Promoteur s’engage à indemniser
					la Caisse d’Epargne Côte d’Azur de l’entier préjudice
					résultant d’une faute de sa part en
					ce compris les frais d’avocats et autres frais judiciaires (dépens, huissiers).
				</p>
				<p>
					Le Promoteur s’engage à ne pas modifier, essayer
					de modifier ou porter atteinte au Service
					sous quelque manière que ce soit et à ne pas utiliser de logiciel ou toute forme de
					programme informatique ayant pour but d’atteindre ou de rendre disponible un Programme
					immobilier protégé ou non disponible librement. Il est également interdit de créer une
					œuvre ou un site dérivant de tout ou partie du Site.
				</p>
				<p>
					Le Promoteur s&apos;engage à informer la Caisse d’Epargne Côte d’Azur sans délai,
					par tous moyens, de toute erreur, faute ou irrégularité qu’il constaterait dans
					l’utilisation du Service, et ce, dès qu’il en a connaissance.
				</p>
				<p>
					Toutefois, à l’occasion d’une demande d’une autorité (administrative, judiciaire, etc.),
					d’une alerte émanant d’un autre Promoteur, la Caisse d’Epargne Côte d’Azur se réserve
					le droit le cas échéant :
				</p>
				<ul>
					<li>
						de conserver, à des fins de preuve, tout Programme immobilier ne respectant pas les
						règles fixées aux présentes mais également tous logs ou traces de connexion imputant
						la gestion ou le contrôle du Programme immobilier au Promoteur
					</li>
					<li>
						de conserver, à des fins de preuve, tous logs, traces de connexion ou données à
						caractère personnel ou non prouvant l’imputabilité au Promoteur des comportements
						ou actions en cause
					</li>
					<li>
						de conserver, à des fins de preuve, tous logs, traces de connexion ou données à
						caractère personnel ou non prouvant l’imputabilité au Promoteur des comportements
						ou actions en cause
					</li>
				</ul>
				<p>
					Le Promoteur garantit la Caisse d’Epargne Côte d’Azur contre tout acte dommageable
					qu’il commettrait à l’égard d’un autre Promoteur.
				</p>
				<p>
					La Caisse d’Epargne Côte d’Azur ne consent aucune garantie sur l’aptitude du Site
					à répondre à des attentes ou besoins particuliers de tout Promoteur. De la même
					manière, la Caisse d’Epargne Côte d’Azur n’est pas en mesure de garantir qu’aucune
					erreur ou autre trouble de fonctionnement ou d’utilisation n’apparaîtra au cours
					de l’utilisation du Site.
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 8 – Responsabilité du Promoteur</h2>
				<p>
					La Caisse d’Epargne Côte d’Azur décline toute responsabilité quant aux Programmes
					validés par le Promoteur, la validation des Programmes étant réservée aux seuls
					Promoteurs ainsi que de leur appréciation par une quelconque administration ou
					juridiction.
				</p>
				<p>
					De même, la Caisse d’Epargne Côte d’Azur décline toute responsabilité à l’égard
					de l’usage qui est fait du Site par tout Promoteur, notamment en ce qui concerne
					la qualité et la véracité des informations communiquées au public.
				</p>
				<p>
					La Caisse d’Epargne Côte d’Azur ne garantit pas que les résultats et les informations
					obtenus soient exempts de toute erreur ou de tout autre défaut. La Caisse d’Epargne
					Côte d’Azur est tenue, s’agissant de la fourniture du Service, par une obligation
					de moyens.
				</p>
				<p>
					La Caisse d’Epargne Côte d’Azur n’est en aucun cas responsable des contenus des
					Programmes immobiliers hébergés dans l’Espace Promoteur. La Caisse d’Epargne
					Côte d’Azur en sa qualité de prestataire notamment en sa qualité d’hébergeur,
					ne pourra voir sa responsabilité recherchée ni engagée du fait des activités
					ou des informations stockées à la demande d’un Promoteur, si elle n’avait pas
					effectivement connaissance de leur caractère illicite ou de faits et circonstances
					faisant apparaître ce caractère ou si, dès le moment où elle en a eu connaissance,
					elle a agi promptement pour retirer ces informations ou en rendre l’accès impossible.
				</p>
				<p>
					A cet égard, et conformément aux stipulations de l’article « Résiliation Suspension
					Suppression », la Caisse d’Epargne Côte d’Azur se réserve le droit de retirer ou
					de suspendre l’accès à tout Programme à la suite de la réception d’une notification
					valable conformément aux textes en vigueur ou si elle a effectivement
					connaissance du caractère manifestement illicite du Programme.
				</p>
				<p>
					La responsabilité de la Caisse d’Epargne Côte d’Azur ne pourra en aucun cas être
					recherchée en raison de ce retrait.
				</p>
				<p>
					La responsabilité de la Caisse d’Epargne Côte d’Azur ne peut être engagée à aucun
					titre, en cas de partage du Programme par le Promoteur avec un tiers au Service.
				</p>
				<p>
					En aucun cas, la Caisse d’Epargne Côte d’Azur n’est responsable des préjudices
					tels que notamment : préjudice financier, commercial, perte de clientèle,
					trouble commercial quelconque, perte de bénéfice, perte d’image de marque
					subis par le Promoteur qui pourraient résulter de l’inexécution des présentes
					conditions générales, lesquels préjudices sont, de convention expresse, réputés
					avoir le caractère de préjudice indirect.
				</p>
				<p>
					La responsabilité de la Caisse d’Epargne Côte d’Azur ne pourra être retenue
					si le Conseiller Caisse d’Epargne Côte d’Azur refuse un financement pour un
					Programme immobilier proposé via un Espace Promoteur pour des motifs ayant
					trait à la qualité du dossier de prêt.
				</p>
				<p>
					La Caisse d’Epargne Côte d’Azur ne sera en aucun cas responsable des dommages
					consécutifs, même partiellement, à une inexécution totale ou partielle de ses
					obligations par le Promoteur, ainsi que de tous dommages indirects même si elle
					a eu connaissance de la possibilité de survenance de tels dommages.
				</p>
				<p>
					La Caisse d’Epargne Côte d’Azur ne sera responsable et ne sera réputée avoir
					manqué à ses obligations en cas d’inexécution de la totalité ou d’une partie
					de celles-ci ou d’une perturbation dans leur exécution si ce manquement est
					dû à un cas de force majeure. Dans ce cas la Caisse d’Epargne Côte d’Azur informera
					le Promoteur et recherchera avec lui les mesures à prendre pour remédier à
					la situation créée.
				</p>
				<p>
					Le Promoteur garantira et indemnisera la Caisse d’Epargne Côte d’Azur contre tout
					dommage subi par la Caisse d’Epargne Côte d’Azur et
					contre toute action en responsabilité
					qui serait engagée à l’encontre de la Caisse d’Epargne Côte d’Azur sur le fondement de
					la violation des Conditions Générales d’Utilisation et/ou d&apos;un droit quelconque
					d&apos;un tiers par le Promoteur.
				</p>
				<p>
					La Caisse d’Epargne Côte d’Azur n’est pas responsable de la réussite commerciale des
					Programmes d’un Promoteur.
				</p>
				<p>
					La Caisse d’Epargne Côte d’Azur n’est pas responsable de l’indisponibilité des réseaux
					(logiciel ou matériel) qui ne sont pas entièrement sous son contrôle direct, ni de
					toute modification, suspension ou interruption de diffusion du Service, ainsi que de
					la continuité, pérennité, conformité, compatibilité ou performance de ceux-ci ou à
					l’absence de bugs.
				</p>
				<p>
					En outre, la Caisse d’Epargne Côte d’Azur n’assume aucun engagement ni responsabilité :
				</p>
				<ul>
					<li>
						quant à l’utilisation du Service non conforme à la réglementation en vigueur relative
						à la protection des logiciels
					</li>
					<li>
						quant à l’usure normale des média informatiques du Promoteur, ou à la détérioration
						des informations portées sur lesdits média informatiques due à l’influence des champs
						magnétiques
					</li>
					<li>
						du fait d’informations, d’images, de sons, de textes, de vidéos contraires aux
						législations et réglementations en vigueur
					</li>
				</ul>
				<p>
					Le Promoteur s’engage à respecter la charte d’utilisation
					(voir en annexes la charte d’utilisation).
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 9 - Secret professionnel</h2>
				<p>
					La Caisse d’Epargne Côte d’Azur est tenue au secret professionnel, conformément à
					l’article L.511-33 du Code monétaire et financier.
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 10 - Convention sur la preuve</h2>
				<p>La création par le Promoteur de son Espace est facultative.</p>
				<p>
					Toutefois, lors de la création de son Espace, le Promoteur doit communiquer à la
					Caisse d’Epargne Côte d’Azur des données à caractère personnel listées à l’article
					4 qui sont nécessaires (pour les champs marqués d’une étoile) pour accéder et pour
					utiliser le Service objet des présentes CGU.
					Ces données sont utilisées par la Caisse d’Epargne Côte d’Azur exclusivement à
					des fins de :
				</p>
				<ul>
					<li>Gestion de l’Espace du promoteur</li>
					<li>Création du programme</li>
					<li>Suivi de l’état d’avancement de la publication du programme</li>
					<li>Contact</li>
				</ul>
				<p>
					Ces données sont conservées par la Caisse d’Epargne Côte d’Azur pour la
					durée strictement nécessaire à la réalisation des finalités visées ci-dessus.
				</p>
				<p>
					Elles ne sont pas traitées à des fins de prospection commerciale et elles
					ne sont pas communiquées ou cédées à des tiers, sous réserve de l’accord
					préalable du Promoteur. Certaines données peuvent toutefois être adressées,
					à leur demande, aux autorités légalement habilitées, notamment dans le cadre
					de la lutte contre le blanchiment des capitaux ou de la lutte contre le
					financement du terrorisme.
				</p>
				<p>
					Conformément à la règlementation en vigueur, le Promoteur dispose d’un droit d’accès,
					de rectification et de suppression des informations qu’il a communiquées ainsi que
					d’un droit d’opposition au traitement des données qui le concernent et de limitation
					au traitement de ses données. Le Promoteur est également informé qu’il dispose du droit
					de définir des directives permettant
					l’accès à ses données en cas de décès (les modalités
					d’exercice de ce droit sont actuellement en attente de précisions par décret).
					Les demandes portant sur ces droits peuvent être exercées directement en adressant un
					courrier électronique à <a href={`mailto:${appPublicEmail}`}> {appPublicEmail}</a>.
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 11 - Protection des données à caractère personnel</h2>
				<p>
					Le Promoteur est informé que ce site a recours à des témoins de connexion
					(usuellement dénommé « cookie ») qui peuvent
					s’installer automatiquement et être conservés
					temporairement en mémoire ou sur son disque dur.
					Les cookies utilisés permettent notamment
					de faciliter la navigation sur le Site. Un cookie est un élément qui peut servir
					à enregistrer des informations relatives à la navigation du Promoteur et de tout
					Utilisateur du Site.Le Promoteur qui le désire peut refuser les cookies en modifiant les
					paramètres de son navigateur.
				</p>
				<p>Les cookies mis en œuvre via l’Espace ne permettent pas un traçage de l’internaute.</p>
				<p>
					Cette manipulation entraînera cependant la suppression de tous les cookies utilisés
					par le navigateur, y compris ceux employés par d’autres sites internet, ce qui peut
					conduire à la perte de certaines informations ou réglages.
				</p>
				<p>
					La suppression des cookies peut être de nature à altérer, voire à rendre
					impossible la navigation sur le Site.
				</p>
				<p>
					Pour plus d’informations, notamment concernant la suppression et la gestion
					des cookies, le Promoteur est invité à consulter la page internet
					suivante <a href="./politique-cookies">Politique des Cookies</a>
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 12 – Force majeure</h2>
				<p>
					La Caisse d’Epargne Côte d’Azur ne saurait être tenue responsable pour tout retard dans
					l’exécution de ses obligations ou pour toute inexécution de ses obligations résultant
					des présentes Conditions générales d’utilisation lorsque les circonstances
					y donnant lieu
					relèvent de la force majeure.
				</p>
				<p>
					De façon expresse, sont considérés comme cas de force majeure ou cas fortuit, outre ceux
					habituellement retenus par la jurisprudence des cours et tribunaux français, les
					cas suivants: Blocage des communications électroniques, y compris des réseaux de
					communications électroniques, non prévisible par la Caisse d’Epargne Côte d’Azur,
					remettant en cause les normes et standards de sa profession et tout autre cas
					indépendant de la volonté des parties empêchant l’exécution normale des
					obligations découlant des CGU.
				</p>
				<p>
					Tout cas de force majeure affectant l’exécution des obligations
					résultantes des présentes
					Conditions générales d’utilisation et notamment
					l’accès ou l’utilisation du Service par le
					Promoteur suspendra, dès sa date de survenance,
					l’exécution des présentes Conditions
					générales. A partir de cette date, et malgré le cas de force
					majeure, la Caisse d’Epargne
					Côte d’Azur s’efforcera dans la mesure du possible :
				</p>
				<ul>
					<li>d’informer les Promoteurs de l’existence de ce cas de force majeure</li>
					<li>de rétablir un accès, même dégradé, au Service</li>
					<li>
						ou de mettre en œuvre toute autre solution technique permettant aux
						Promoteurs de retrouver un accès à leurs Programmes
					</li>
				</ul>
				<p>
					Il est expressément convenu entre les Parties que la mise en œuvre tout à fait
					exceptionnelle de ces moyens palliatifs par la Caisse d’Epargne Côte d’Azur pendant
					la survenance d’un cas de force majeure ne pourra donner lieu à aucune responsabilité
					ou indemnisation de la part de la Caisse d’Epargne Côte d’Azur.
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 13 – Prise d’effet/Durée</h2>
				<p>
					Les présentes CGU prennent effet à compter de leur acceptation par le Promoteur et
					durent tant que la convention entre le Promoteur et la Caisse d’Epargne Côte d’Azur
					ou les présentes CGU n’ont pas été résiliées selon les conditions figurant aux
					articles « Résiliation » desdits documents.
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 14 – Modalités financières</h2>
				<p>
					L’inscription au Service est gratuite. Par contre, les Mises en relation peuvent donner
					lieu à commission selon les conditions figurant dans la convention entre le Promoteur
					et la Caisse d’Epargne Côte d’Azur.
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 15 – Résiliation – Suspension - Suppression</h2>
				<p>
					Le Promoteur pourra résilier son Espace en envoyant un courrier électronique à la Caisse
					d’Epargne Côte d’Azur. Le Promoteur devra préalablement sauvegarder et supprimer
					les Programmes ces derniers étant accessibles pendant 4 semaines à compter de
					l’envoi de ce mail.
				</p>
				<p>
					La Caisse d’Epargne Côte d’Azur se réserve le droit d’interrompre, de façon temporaire
					ou définitive, tout ou partie du Service pour des raisons liées à la sécurité
					du Service, à la sécurité du Promoteur ou à un manquement ou de suspicion de manquement
					du Promoteur à l’une de ses obligations visées aux présentes. En cas de suspension
					temporaire, un courrier électronique est envoyé au Promoteur, l’informant du motif et
					de la durée de la suspension. Pendant cette période, aucune Mise en relation ne peut
					être effectuée.
				</p>
				<p>
					Après une suspension n’ayant pas modifié le comportement du Promoteur, la
					Caisse d’Epargne Côte d’Azur se réserve le droit de résilier les Conditions
					Générales avec le Promoteur sans préavis.
				</p>
				<p>
					La Caisse d’Epargne Côte d’Azur se réserve également le droit de mettre unilatéralement
					fin à la présente relation contractuelle résultant des CGU au cas où le Promoteur ferait
					preuve de manquements graves et/ou répétés à l’une de ses obligations figurant dans les
					présentes ou encore dans le cas où la Caisse d’Epargne Côte d’Azur arrête définitivement
					le recours au Service. Dans ce dernier cas, la résiliation sera effective X mois après
					l’information de la fin du Service.
				</p>
				<p>
					Cette résiliation se fera de plein droit, sans préjudice des dommages-intérêts que
					la Caisse d’Epargne Côte d’Azur pourrait solliciter.
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 16 – Cessibilité des Conditions Générales</h2>
				<p>
					La Caisse d’Epargne Côte d’Azur se réserve la faculté de céder, transférer ou apporter
					à un tiers, sous quelque forme que ce soit, les droits et obligations nés des
					présentes CGU.
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 17 –Propriété Intellectuelle</h2>
				<p>
					Le Site est la propriété de la Caisse d’Epargne Côte d’Azur, à l’exception des marques,
					logos ou contenus appartenant à d’autres sociétés partenaires ou auteurs.
					Elle exploite le Service.
				</p>
				<p>
					Dans la mesure où la Caisse d’Epargne Côte d’Azur n’effectue aucun contrôle a priori des
					Programmes validés, le Promoteur garantit qu’il possède la totalité des droits de
					propriété intellectuelle et industrielle sur tous les Programmes validés par le
					Promoteur. Le Promoteur garantit la Caisse d’Epargne Côte d’Azur contre toute action,
					revendication ou opposition qui serait engagée à son encontre de la part de toute
					personne au motif notamment que les Programmes du Promoteur constituent une contrefaçon
					de droits préexistants de propriété intellectuelle revendiqués par des tiers, ou un
					acte de concurrence déloyale et/ou parasitaire auquel l’exécution des Conditions
					Générales aurait porté atteinte En particulier, il sera seul responsable de tout acte de
					contrefaçon, de parasitisme ou toute violation de droits de propriété intellectuelle
					et plus largement de tout préjudice direct ou indirect matériel ou corporel causé
					par l’utilisation des supports ou activités disponibles sur le Site.
				</p>
				<p>
					Dans ce cadre, le Promoteur accorde à la Caisse d’Epargne Côte d’Azur une licence, dans
					le monde entier, pour les droits d’exploitation et de reproduction des Programmes
					ainsi hébergés dans le seul objectif de l’exploitation, la promotion ou
					l’amélioration du Service.
				</p>
				<p>
					Toute représentation ou reproduction en partie ou en totalité est interdite
					sans l&apos;accord exprès de la Caisse d’Epargne Côte d’Azur et constituerait
					une contrefaçon sanctionnée par les articles L. 335-2 et suivants du Code de
					la Propriété Intellectuelle.
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 18 – Autorisation d’exploitation du droit à l’image</h2>
				<p>
					Le Promoteur autorise la Caisse d’Epargne Côte d’Azur à reproduire et exploiter l’image
					fixée dans le cadre de la promotion et la communication autour du Service sur tous
					supports matériels et immatériels, en tous formats connus ou inconnus à ce jour pour
					une durée de 10 ans.
				</p>
				<p>
					Le Promoteur garantit n’être lié par aucun accord avec un tiers, de quelque
					nature que ce soit, ayant pour objet ou pour effet de limiter ou empêcher la
					mise en œuvre de la présente autorisation et accord.
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 19 – Convention sur la preuve</h2>
				<p>
					Les Parties entendent fixer, dans le cadre du Service, les règles relatives aux preuves
					recevables entre eux en cas de litige et à leur force probante. Les stipulations qui
					suivent constituent ainsi la convention de preuve passée entre les parties, lesquelles
					s’engagent à respecter le présent article.
				</p>
				<p>
					Les Parties acceptent qu’en cas de litige les moyens d’Identification utilisés dans le
					cadre du Service soient admissibles devant les tribunaux et fassent preuve des données
					et des faits qu’ils contiennent ainsi que des signatures et procédés d’identification et
					d’authentification qu’ils expriment.
				</p>
				<p>
					Les Parties acceptent qu’en cas de litige, les données de connexion relatives à des
					actions effectuées à partir des Espaces soient admissibles devant les tribunaux et
					fassent preuve des données et des faits qu’ils contiennent.
				</p>
				<p>
					Les Parties acceptent qu’en cas de litige, tout ou partie du Programme validé par
					le Promoteur lui soit présumé imputable.
				</p>
				<p>
					Les Parties s’engagent à accepter qu’en cas de litige, les Contenus archivés,
					les messages électroniques, les Mises en relation, les accusés de réception échangés
					soient admissibles devant les tribunaux et fassent preuve des données et des faits
					qu’ils contiennent.
				</p>
				<p>La preuve contraire peut être rapportée.</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 20 – Sous traitance</h2>
				<p>
					La Caisse d’Epargne Côte d’Azur pourra sous-traiter tout ou partie de ses obligations
					à des tiers, à condition cependant que la Caisse d’Epargne Côte d’Azur reste
					garante de la bonne exécution des présentes.
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 21 – Intégralité des relations</h2>
				<p>
					Les Parties reconnaissent que les dispositions des Conditions Générales constituent
					l’intégralité des accords intervenus entre elles en ce qui concerne la réalisation
					de l’objet des présentes et annulent et remplacent tous accords ou propositions
					antérieures ayant le même objet, quelle qu’en soit la forme.
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 22 – Loi applicable et juridiction compétente</h2>
				<p>
					Les parties conviennent que la loi applicable est la loi Française interne,
					que les litiges sont de la compétence des tribunaux Français.
				</p>
				<p>
					A défaut de règlement amiable, en cas de litige relatif à l’interprétation,
					la formation ou l’exécution des Conditions générales d’utilisation et faute
					d’être parvenus à un accord amiable ou à une transaction, les Parties donnent
					compétence expresse et exclusive aux tribunaux compétents du ressort de la
					Cour d’appel d’Aix en Provence, nonobstant pluralité de défendeurs ou d’action
					en référé ou d’appel en garantie ou de mesure conservatoire.
				</p>
			</LegalArticle>
			<p className="LegalSection-notes">
				Caisse d’Epargne et de Prévoyance Côte d’Azur, société anonyme coopérative à directoire et
				conseil d’orientation et de surveillance, régie par les articles L.512-85 et suivants du
				Code monétaire et financier – Capital social 395.033.520 euros – 455, promenade des Anglais,
				06200 Nice – 384 402 871 RCS NICE – Intermédiaire d’assurance, immatriculé à l’ORIAS sous
				le n° 07 002 199. Titulaire de la carte professionnelle Transactions sur immeubles et fonds
				de commerce, sans perception d’effets ou valeurs, n° 10480 délivrée par la Préfecture des
				Alpes-Maritimes, garantie par la Compagnie Européenne de Garanties et Cautions, 16 rue
				Hoche, Tour KUPKA B, TSA 39999, 92919 LA DEFENSE Cedex
			</p>
		</LegalSection>

	</section>
);

LegalCGU.propTypes = {
	appName: PropTypes.string.isRequired,
	appPublicEmail: PropTypes.string.isRequired,
	appUrl: PropTypes.string.isRequired,
	appWWW: PropTypes.string.isRequired,
};

export default LegalCGU;
