import React from 'react';

import PageContent from '@cecaz-immo/ui/dist/components/page/content';

import LegalCGU from '../components/legal/cgu';

const {
	REACT_APP_NAME,
	REACT_APP_EMAIL_PUBLIC,
	REACT_APP_URL,
	REACT_APP_WWW,
} = process.env;


const PageLegal = () => (
	<PageContent className="PageLegal">
		<section className="page-center section last-section">
			<LegalCGU
				appName={REACT_APP_NAME}
				appLegalEmail={REACT_APP_EMAIL_PUBLIC}
				appUrl={REACT_APP_URL}
				appWWW={REACT_APP_WWW}
			/>
		</section>
	</PageContent>
);

export default PageLegal;
