import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import FieldAdmin from '@cecaz-immo/ui/dist/components/form/field/admin';
import ButtonLoading from '@cecaz-immo/ui/dist/components/button/Loading';
import AlertMessage from '../../alert-message/alert-message';
import AuthForm from '../form/form';

// schemas
import { forgotPasswordSchema } from '../../../schemas/auth';
// libs
import { forgotPassword } from '../../../lib/session';

class AuthFormsForgotPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			display: false,
			title: '',
			message: '',
			success: false,
			email: '',
		};

		this.onSubmitForgotPassword = this.onSubmitForgotPassword.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	onSubmitForgotPassword(e) {
		e.preventDefault();

		const {
			history,
			userType,
		} = this.props;
		const { email } = this.state;

		this.setState({ loading: true });

		forgotPassword(/*username*/ email, email, userType)
			.then(() => {
				history.push(`/auth/forgot-password/reset-password/${email}`);
			})
			.catch((err) => {
				let title = '';
				let message = '';
				console.log('erreur ', err);
				if (err.code === 'LimitExceededException') {
					title = 'Opération interdite';
					message = 'Vous avez atteint le nombre maximum de '
						+ 'tentatives pour réinitialiser votre mot de passe.'
						+ ' Veuillez réessayer plus tard.';
				} else {
					title = 'Erreur identifiant';
					message = 'L\'email saisi est introuvable.';
				}
				this.setState({
					display: true,
					title,
					message,
					loading: false,
					success: false,
					email: '',
				});
			});
	}

	handleChange(data) {
		this.setState(data);
	}

	render() {
		const {
			display,
			email,
			loading,
			message,
			success,
			title,
		} = this.state;

		return (
			<AuthForm
				data={{ email }}
				schema={forgotPasswordSchema}
				onSubmit={this.onSubmitForgotPassword}
				onChange={this.handleChange}
			>
				<h3 className="title">Mot de passe oublié ?</h3>
				<div className="row">
					<FieldAdmin
						component="input"
						type="text"
						name="email"
						className="field"
						placeholder="Votre email"
						value={email}
						errorMessages={{
							format: () => 'Email non valide',
						}}
					/>
				</div>
				<ButtonLoading
					outline
					color="primary"
					loading={loading}
					type="submit"
				>
					Envoyer
				</ButtonLoading>
				<AlertMessage
					display={display}
					title={title}
					message={message}
					success={success}
				/>
			</AuthForm>
		);
	}
}

AuthFormsForgotPassword.propTypes = {
	history: PropTypes.shape({ push: PropTypes.func }),
	userType: PropTypes.string.isRequired,
};

AuthFormsForgotPassword.defaultProps = {
	history: {},
};

export default AuthFormsForgotPassword;
