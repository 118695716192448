import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import memoize from 'memoize-one';

import { calculatePricesAndRoomsProgramme } from '@cecaz-immo/app-client/dist/store/actions/programme';
import ProgrammeListItem from '../../programme-list-item';

import ModalConfirm from '../../../modal/confirm/confirm';
import { deleteProgramme as deleteProgrammeAction } from '../../../../store/actions/edit-programme';

import './item.scss';

const statusConfig = {
	TO_CORRECT: {
		label: 'à corriger',
		className: 'to-correct',
	},
	WAITING: {
		label: 'en attente de validation',
		className: 'waiting',
	},
	PUBLISHED: {
		label: 'publié',
		className: 'published',
	},
	FINISHED: {
		label: 'supprimé',
		className: 'finished',
	},
};

class ProgrammeEditListItem extends React.Component {
	constructor(props) {
		super(props);

		this.onCancel = this.onCancel.bind(this);
		this.onClickDelete = this.onClickDelete.bind(this);
		this.onConfirm = this.onConfirm.bind(this);

		this.state = {
			confirmModalIsOpen: false,
		};
		this.memoPriceRoomsProgramme = memoize(calculatePricesAndRoomsProgramme);
	}

	onClickDelete(e) {
		e.stopPropagation();
		this.openModal();
	}

	onCancel() {
		this.closeModal();
	}

	onConfirm() {
		const { deleteProgramme, programme } = this.props;
		deleteProgramme(programme);
		this.closeModal();
	}

	closeModal() {
		this.setState({ confirmModalIsOpen: false });
	}

	openModal() {
		this.setState({
			confirmModalIsOpen: true,
		});
	}

	render() {
		const { displayEditButton, programme } = this.props;
		const { confirmModalIsOpen } = this.state;
		const finished = programme.status === 'FINISHED';
		this.memoPriceRoomsProgramme(programme);
		return (
			<>
				<ProgrammeListItem
					programme={programme}
					editionDetail
					Actions={(
						<div className="ProgrammeEditListItem-actions">
							{
								(displayEditButton && !finished)
								&& (
									<Link
										to={`/programme/edit/${programme.uuid}`}
										className="btn icon btn-outline-secondary btn-sm"
										title="Modifier le programme"
									>
										<i className="fa fa-edit" />
									</Link>
								)
							}
							{
								!finished
								&& (
									<Button
										className="btn icon"
										color="danger"
										onClick={this.onClickDelete}
										size="sm"
										outline
										title="Supprimer le programme"
									>
										<i className="fa fa-trash" />
									</Button>
								)
							}
						</div>
					)}
					Overlay={(
						<span className={`status ${statusConfig[programme.status].className}`}>
							{statusConfig[programme.status].label}
						</span>
					)}
				/>
				<ModalConfirm
					isOpen={confirmModalIsOpen}
					message={
						(
							<>
								Vous êtes sur le point de <b>supprimer un programme</b>.
								Cette action est <b>irréversible</b>.<br />
								<br />
								Voulez-vous continuer?
							</>
						)
					}
					onCancel={this.onCancel}
					onConfirm={this.onConfirm}
				/>
			</>
		);
	}
}

ProgrammeEditListItem.propTypes = {
	programme: PropTypes.shape({
		status: PropTypes.string,
		uuid: PropTypes.string,
	}).isRequired,
	deleteProgramme: PropTypes.func.isRequired,
	displayEditButton: PropTypes.bool,
};

ProgrammeEditListItem.defaultProps = {
	displayEditButton: false,
};

const mapDispatchToProps = (dispatch) => ({
	deleteProgramme: (programme) => dispatch(deleteProgrammeAction(programme)),
});

const ProgrammeEditListItemContainer = connect(
	null,
	mapDispatchToProps,
)(ProgrammeEditListItem);

export default ProgrammeEditListItemContainer;
