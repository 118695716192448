import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../modal';
import './confirm.scss';

const ModalConfirm = ({
	btnCancelLabel,
	btnConfirmLabel,
	message,
	onCancel,
	onConfirm,
	title,
	...rest
}) => (
	<Modal
		onRequestClose={onCancel}
		title={title}
		{...rest}
	>
		<form onSubmit={(e) => { e.preventDefault(); }}>
			<p className="ModalConfirm-message">
				{message}
			</p>
			<footer className="ModalConfirm-footer">
				<input
					className="ModalConfirm-btn btn"
					onClick={onConfirm}
					type="submit"
					value={btnConfirmLabel}
				/>
				<input
					className="ModalConfirm-btn btn"
					onClick={onCancel}
					type="submit"
					value={btnCancelLabel}
				/>
			</footer>
		</form>
	</Modal>
);

ModalConfirm.propTypes = {
	btnCancelLabel: PropTypes.node,
	btnConfirmLabel: PropTypes.node,
	message: PropTypes.node,
	onCancel: PropTypes.func,
	onConfirm: PropTypes.func,
	title: PropTypes.node,
};

ModalConfirm.defaultProps = {
	btnCancelLabel: 'Annuler',
	btnConfirmLabel: 'Continuer',
	message: 'Voulez-vous continuer ?',
	onCancel: null,
	onConfirm: null,
	title: 'Attention',
};

export default ModalConfirm;
