import React from 'react';
import PropTypes from 'prop-types';

import './nav.scss';

const Nav = (props) => {
	const { children, className } = props;
	return (
		<nav className={`Nav ${className}`}>
			<ul className="list-undecorated">
				{ children }
			</ul>
		</nav>);
};

Nav.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

Nav.defaultProps = {
	children: null,
	className: '',
};

export default Nav;
