const programmeSchema = {
	type: 'object',
	properties: {
		name: { type: 'string', maxLength: 45 },
		address: { type: 'string', maxLength: 255 },
		district: { type: 'string', maxLength: 45 },
		floors: { type: 'number', minimum: 1 },
		lat: { type: 'number', minimum: -90, maximum: 90 },
		lng: { type: 'number', minimum: -90, maximum: 90 },
		saleStartDate: { type: 'string', format: 'date-time' },
		reducedVat: { type: 'boolean', enum: [true, false] },
		pinelLaw: { type: 'boolean', enum: [true, false] },
		yearDelivery: { type: 'string' },
		quarterDelivery: { type: 'number', minimum: 1, maximum: 4 },
		description: { type: 'string', maxLength: 2000 },
		files: {
			type: 'array',
			minItems: 1,
			items: {
				type: 'object',
				properties: {
					name: { type: 'string' },
					order: { type: 'number', minimun: 0 },
					type: { type: 'string' },
				},
				require: [],
			},
		},
		lots: {
			type: 'array',
			minItems: 1,
			items: {
				type: 'object',
				properties: {
					type: { type: 'string', enum: ['FLAT', 'HOUSE', 'PLOT', 'COMMERCIAL'] },
					rooms: { type: 'integer', minimum: 1 },
					minArea: { type: 'number', minimum: 0 },
					maxArea: { type: 'number', minimum: 0 },
					minPrice: { type: 'number', minimum: 0 },
					maxPrice: { type: 'number', minimum: 0 },
					amount: { type: 'integer', minimum: 0 },
					containParking: { type: 'boolean', enum: [true, false] },
				},
				required: [
					'type',
					'rooms',
					'minArea',
					'maxArea',
					'minPrice',
					'maxPrice',
					'amount',
					'containParking',
				],
			},
		},
		coordinates: {
			type: 'object',
			properties: {
				bounds: {
					type: 'object',
					properties: {
						north: { type: 'number', minimum: 1 },
						south: { type: 'number', minimum: 1 },
						east: { type: 'number', minimum: 1 },
						west: { type: 'number', minimum: 1 },
					},
					required: [
						'north',
						'south',
						'east',
						'west',
					],
				},
			},
			required: [
				'bounds',
			],
		},
	},
	required: [
		'name',
		'address',
		'district',
		'floors',
		// 'lat',
		// 'lng',
		'saleStartDate',
		'yearDelivery',
		'quarterDelivery',
		'description',
		'files',
		'lots',
	],
};

export default programmeSchema;
