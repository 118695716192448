import React from 'react';
import PropTypes from 'prop-types';
import FormButton from '@cecaz-immo/ui/dist/components/form/button/button';

import './upload-files.scss';

const UploadFiles = ({ className, loading }) => (
	<section className={`UploadFiles ${className}`}>
		<i className="fa fa-upload icon" />
		<span className="instruction">Faites glisser les photos ici</span>
		<span className="detail">Vous pouvez également ...</span>
		<FormButton className="btn" type="button" loading={loading}>
			Séléctionner les photos à importer
		</FormButton>
		<ul className="list-undecorated list-details">
			<li>
				<span className="detail">Fichiers acceptés : jpg, png, mp4</span>
			</li>
			<li>
				<span className="detail">Taille maximum : 40 Mo</span>
			</li>
			<li>
				<span className="detail">Résolution recommandée : 2000px x 1500px</span>
			</li>
			<li>
				<span className="detail">Format recommandé : 4:3 </span>
			</li>
		</ul>
	</section>
);

UploadFiles.propTypes = {
	className: PropTypes.string,
	loading: PropTypes.bool,
};

UploadFiles.defaultProps = {
	className: '',
	loading: false,
};

export default UploadFiles;
