import PropTypes from 'prop-types';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { hasSessionOneOfRoles } from '../../lib/session';
import WithSessionContainer from './session';

const AuthorizationSetter = (props) => {
	const {
		Component,
		render,
		...rest
	} = props;

	const {
		role,
		session,
	} = rest;

	const authorized = hasSessionOneOfRoles(session, role);
	const newProps = Object.assign({ authorized }, rest);

	if (Component) return React.createElement(Component, newProps);
	if (render) return render(newProps);
	return null;
};

AuthorizationSetter.propTypes = {
	Component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	render: PropTypes.func,
	role: PropTypes.string.isRequired,
	session: PropTypes.shape({}).isRequired,
};

AuthorizationSetter.defaultProps = {
	Component: null,
	render: null,
};

const getAuthorizationSetter = (role, Component, render) => props => (
	<AuthorizationSetter
		Component={Component}
		render={render}
		role={role}
		{...props}
	/>
);

export const withRole = role => (Component) => {
	const SetAuthorized = getAuthorizationSetter(role, Component);
	return props => (<WithSessionContainer Component={SetAuthorized} {...props} />);
};

export const WithRole = (p) => {
	const checkAuthorization = (props) => {
		const { session } = props;
		const authorized = hasSessionOneOfRoles(session, p.role);
		if (authorized.length) {
			return p.children;
		}
		return null;
	};

	return <WithSessionContainer render={checkAuthorization} {...p} />;
};

export const RouteIfHasRole = (p) => {
	const notAuthorizedRedirection = p.notAuthorizedRedirection || '/forbidden';
	const notConnectedRedirection = p.notConnectedRedirection || '/auth/signin';

	const { component, role } = p;
	const Component = component;
	const routeProps = Object.assign({}, p);
	delete routeProps.component;

	const renderComponentIfAllowedOrRedirect = (props) => {
		const { authorized, session } = props;
		if (authorized) return <Component {...props} />;
		if (!session || !session.valid) return <Redirect to={notConnectedRedirection} />;
		return <Redirect to={notAuthorizedRedirection} />;
	};

	const SetAuthorized = getAuthorizationSetter(role, null, renderComponentIfAllowedOrRedirect);

	const render = props => <WithSessionContainer Component={SetAuthorized} {...props} />;

	return <Route {...routeProps} render={render} />;
};
