import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import './header.scss';


class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = { top: false };
		this.handleScroll = this.handleScroll.bind(this);
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll() {
		this.setState({
			top: window.pageYOffset <= 10,
		});
	}

	render() {
		const { children, fixed, logo } = this.props;
		const { top } = this.state;
		return (
			<header className={`Header ${fixed ? 'fixed' : ''} ${!top ? 'scrolled' : ''}`}>
				<div className="Header-container page-center">
					<Link to="/">
						<img
							alt="Accueil"
							src={logo}
							className="Header-logo"
							title="Accueil"
						/>
					</Link>
					{ children }
				</div>
			</header>
		);
	}
}


Header.propTypes = {
	children: PropTypes.node,
	fixed: PropTypes.bool,
	logo: PropTypes.string.isRequired,
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired,
	}).isRequired,
};

Header.defaultProps = {
	children: null,
	fixed: false,
};

export default Header;
