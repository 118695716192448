import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FieldError, Form } from 'react-jsonschema-form-validation';
import PropTypes from 'prop-types';
import {
	Container,
	Col,
	Row,
	CardBody,
	Card,
	Alert,
} from 'reactstrap';
import PageContent from '@cecaz-immo/ui/dist/components/page/content';
import ButtonLoading from '@cecaz-immo/ui/dist/components/button/Loading';
import ProgrammeValidationInfo from '@cecaz-immo/ui/dist/components/programme/validation-info';
import errorMessages from '@cecaz-immo/ui/dist/lib/error-messages';

import AlertMessage from '../../components/alert-message/alert-message';
import ProgrammeEditLots from '../../components/programme/edit/lots/lots';
import ProgrammeEditMedias from '../../components/programme/edit/medias/medias';
import ProgrammeEditGeneral from '../../components/programme/edit/general/general';
import { TO_CORRECT } from '../../lib/status';

import { select } from '../../store/selectors/programme';
import {
	createProgramme as createProgrammeAction,
	getCurrentProgrammeToEdit as getCurrentProgrammeToEditAction,
	updateEditProgrammeAction,
	deleteLotProgramme as deleteLotProgrammeAction,
	createLotProgramme as createLotProgrammeAction,
	updateLotProgramme as updateLotProgrammeAction,
	updateFiles as updateFilesAction,
	putCurrentProgramme as putCurrentProgrammeAction,
} from '../../store/actions/edit-programme';

import programmeSchema from '../../schemas/programme';
import './edit.scss';

// let fakeData = {
// 	address: '61 avenue Simon Veil',
// 	description: 'CEEI NCA - Pépinière accélérateur à Nice',
// 	district: 'Simon Veil',
// 	floors: 2,
// 	lots: [
// 		{
// 			amount: 2,
// 			containParking: true,
// 			maxArea: 50,
// 			maxPrice: 1000,
// 			minArea: 10,
// 			minPrice: 100,
// 			rooms: 1,
// 			type: 'FLAT',
// 		},
// 	],
// 	name: 'nom',
// 	quarterDelivery: 1,
// 	reducedVat: true,
// 	saleStartDate: '2030-11-19T23:00:00.000Z',
// 	tags: [],
// 	yearDelivery: '2019',
// };

const EditProgramme = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const match = useRouteMatch();

	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [displayAlertMessage, setDisplayAlertMessage] = useState(false);
	const [message, setMessage] = useState('');
	const [title, setTitle] = useState('');
	const [isNew, setIsNew] = useState(false);

	const programme = useSelector(select);

	useEffect(() => {
		const { uuid } = match.params;
		if (!uuid) {
			dispatch(createProgrammeAction());
			setIsNew(true);
		} else {
			dispatch(getCurrentProgrammeToEditAction(uuid));
		}
	}, [dispatch, match]);

	const updateCurrentProgramme = (p) => dispatch(updateEditProgrammeAction(p));
	const updateFiles = (files) => dispatch(updateFilesAction(files));
	const deleteLotProgramme = (index) => dispatch(deleteLotProgrammeAction(index));
	const createLotProgramme = (lot) => dispatch(createLotProgrammeAction(lot));
	const updateLotProgramme = (index, lot) => dispatch(updateLotProgrammeAction(index, lot));

	const handleSubmit = () => {
		setLoading(true);
		setDisplayAlertMessage(false);
		global.rollbar.debug('edit handleSubmit ', programme);
		dispatch(putCurrentProgrammeAction())
			.then((p) => {
				history.push(`/programme/${p.name}/p/${p.uuid}`);
			})
			.catch((err) => {
				// eslint-disable-next-line no-console
				console.error(err);
				global.rollbar.error('EditProgramme handleSubmit', err);
				setLoading(false);
				setDisplayAlertMessage(true);
				setSuccess(false);
				setTitle('Une erreur est survenue...');
				setMessage('Une erreur est survenue lors de l\'opération ! Contacter votre administrateur pour plus d\'informations');
			});
	};

	const handleChange = (newData) => {
		// TODO: refactor
		// if (name === 'address') {
		// 	//setLocation(null);
		// 	updateCurrentProgramme({
		// 		coordinates: null,
		// 	});
		// }
		updateCurrentProgramme(newData);
	};
	const { commentary, uuid } = programme;
	return (
		<PageContent className="PageProgrammeEdit">
			<Container>
				<Row>
					{
						programme.status === TO_CORRECT
						&& (
							<ProgrammeValidationInfo
								commentary={commentary}
								uuid={uuid}
							/>
						)
					}
					<Col xs="12" className="py-4 m-0">
						<h1 className="section-title m-0">Édition de programme</h1>
					</Col>
					<Col xs="12" className="mb-5">
						<Form
							className="ProgrammeEdit"
							data={programme}
							errorMessages={errorMessages}
							onChange={handleChange}
							onSubmit={handleSubmit}
							schema={programmeSchema}
						>
							<ProgrammeEditGeneral
								className="mb-4"
								isNew={isNew}
								programme={programme}
								updateCurrentProgramme={updateCurrentProgramme}
							/>
							<ProgrammeEditMedias
								className="mb-4"
								programme={programme}
								updateFiles={updateFiles}
							/>
							<ProgrammeEditLots
								className="mb-4"
								lots={programme && programme.lots}
								deleteLotProgramme={deleteLotProgramme}
								createLotProgramme={createLotProgramme}
								updateLotProgramme={updateLotProgramme}
							/>
							<Card>
								<CardBody className="d-flex align-items-center justify-content-between">
									<FieldError name="*" className="text-align m-0 mr-2 w-auto">
										<Alert color="danger" className="m-0 py-2">
											Les champs en rouge doivent être obligatoirement complétés ou corrigées.
										</Alert>
									</FieldError>
									<ButtonLoading
										className="ml-auto"
										loading={loading}
										type="submit"
									>
										Enregistrer
									</ButtonLoading>
								</CardBody>
							</Card>
							<AlertMessage
								display={displayAlertMessage}
								message={message}
								success={success}
								title={title}
							/>
						</Form>
					</Col>
				</Row>
			</Container>
		</PageContent>
	);
};

EditProgramme.propTypes = {
	history: PropTypes.shape({ push: PropTypes.func }),
	programme: PropTypes.shape({
		commentary: PropTypes.string,
		lots: PropTypes.arrayOf(PropTypes.shape({})),
		uuid: PropTypes.string,
	}),
};

EditProgramme.defaultProps = {
	history: {},
	programme: {},
};

// const setFakeData = (state) => {
// 	if (!process.env.NODE_ENV === 'development') {
// 		return false;
// 	}
// 	if (!state.editProgramme.name) {
// 		state.editProgramme = fakeData;
// 	} else {
// 		fakeData = state.editProgramme;
// 	}

// 	return false;
// };


// const mapStateToProps = state => setFakeData(state) || ({
// 	programme: state.editProgramme,
// });

// const mapDispatchToProps = (dispatch, ownProps) => {
// 	const { uuid } = ownProps.match.params;

// 	return {
// 		createProgramme: () => dispatch(createProgrammeAction()),
// 		getCurrentProgrammeToEdit: () => {
// 			if (!uuid) {
// 				return Promise.reject();
// 			}
// 			return dispatch(getCurrentProgrammeToEditAction(uuid));
// 		},
// 		updateCurrentProgramme: programme => dispatch(updateEditProgrammeAction(programme)),
// 		deleteLotProgramme: index => dispatch(deleteLotProgrammeAction(index)),
// 		createLotProgramme: lot => dispatch(createLotProgrammeAction(lot)),
// 		updateLotProgramme: (index, lot) => dispatch(updateLotProgrammeAction(index, lot)),
// 		updateFiles: files => dispatch(updateFilesAction(files)),
// 		putCurrentProgramme: () => dispatch(putCurrentProgrammeAction()),
// 		updateProgrammeStatus: (status, commentary) => (
// 			dispatch(updateProgrammeStatusAction(status, commentary))),
// 	};
// };

export default EditProgramme;
