import React from 'react';
import PropTypes from 'prop-types';

import './content.scss';

const PageContent = ({ children, className }) => (
	<section className={`PageContent ${className}`}>
		{children}
	</section>
);

PageContent.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

PageContent.defaultProps = {
	className: '',
	children: null,
};

export default PageContent;
