import React from 'react';
import { Route } from 'react-router-dom';

const { REACT_APP_CLIENT_URL } = process.env;

export default ({ path }) => (
    <Route
        path={path}
        component={() => {
            window.location.replace(`${REACT_APP_CLIENT_URL}${path}`);
            return null;
        }}
    />
);