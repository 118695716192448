import React from 'react';
import PropTypes from 'prop-types';

import LegalSection from './section';
import LegalArticle from './article';

const PolitiqueCookies = ({ appLegalEmail, appUrl, appWWW }) => (
	<LegalSection>
		<h1 className="section-title">Politique de cookies</h1>
		<p>
			Caisse d’Epargne Côte d’Azur  est particulièrement attentive à vos
			attentes et nous souhaitons préserver la confiance que vous nous portez.
			Concernant les données personnelles que vous nous
			confiez nous vous informons de manière transparente
			sur notre politique en matière de dépôt et
			d’utilisation des cookies
			depuis notre Site <a href={appUrl}>{appWWW}</a> vers
			votre terminal de connexion.
		</p>
		<LegalArticle>
			<h2>Qu&apos;est-ce qu&apos;un cookie ?</h2>
			<p>
				Les cookies sont de petits fichiers texte, image ou logiciel, qui sont placés et stockés
				sur votre ordinateur, votre smartphone ou tout autre appareil permettant de naviguer sur
				Internet, lorsque vous visitez un site Web.
				Très utiles, les cookies permettent à un site
				Internet de vous reconnaître, de signaler votre passage sur
				telle ou telle page et de vous
				apporter ainsi un service additionnel : amélioration de votre confort de navigation,
				sécurisation de votre connexion ou adaptation du contenu d’une page à vos centres
				d’intérêt.
			</p>
			<p>
				Les informations stockées par les cookies,
				pendant une durée de validité limitée, portent
				notamment sur les pages visitées, les publicités sur lesquelles vous avez cliquées, le
				type de navigateur que vous utilisez et votre adresse IP.
			</p>
		</LegalArticle>
		<LegalArticle>
			<h2>Types de cookies déposés par le site</h2>
			<ul>
				<li>
					<strong>Cookies Statistiques</strong> :
					afin d’adapter notre Site <a href={appUrl}>{appWWW}</a> aux demandes de
					nos visiteurs, nous mesurons le nombre de visites, le nombre de pages consultées
					ainsi que de l&apos;activité des visiteurs sur le « Site »
					et leur fréquence de retour.
					Ces cookies permettent d’établir des statistiques d’analyse de la fréquentation
					à partir desquels les contenus de notre Site sont améliorés en fonction du succès
					rencontré par telle ou telle page auprès de nos visiteurs. <br />
					La durée de validité de ces cookies est de : 12  mois
				</li>
				<li>
					<strong>Cookies permettant de mémoriser</strong> :
					vos préférences d’utilisation, les paramètres
					d’affichage et lecteurs que vous utilisez afin de faciliter votre navigation lors
					de votre prochaine visite sur notre Site.
				</li>
				<li>
					<strong> Cookies destinés à sécuriser votre connexion</strong>:
					lorsque vous ouvrez une session
					sécurisée pour accéder à votre compte en ligne et que celle-ci reste ouverte
					un certain temps, un cookie permet de faire apparaître un message vous invitant
					à fermer votre session et/ou la fermera automatiquement à défaut de réponse.
					<br />
					Ce type de cookie permet également de vous reconnaître pour vous laisser accéder
					à votre espace/compte personnel.
				</li>
			</ul>
		</LegalArticle>
		<LegalArticle>
			<h2>Vos choix en matière de cookie</h2>
			<p>
				Les cookies améliorent votre confort de navigation sur notre Site et sont
				indispensables pour accéder à certains espaces sécurisés. Si vous décidez de
				bloquer tous les cookies par l’intermédiaire de votre navigateur internet, vous
				ne pourrez plus visiter que la partie publique de notre Site et ne pourrez plus
				accéder à votre espace client sécurisé.
			</p>
			<p>
				En poursuivant votre navigation sur notre Site, vous acceptez le dépôt de
				cookies sur votre terminal. Vous pouvez revenir sur cette décision à tout
				moment en vous opposant au dépôt des cookies.
			</p>
		</LegalArticle>
		<LegalArticle>
			<h2>Droit d&apos;accès et d&apos;opposition</h2>
			<p>
				Conformément à la réglementation, vous disposez d’un droit d’opposition et
				d’accès sur les données vous concernant.
			</p>
			<p>
				Vous pouvez exercer votre droit d’accès en nous envoyant par courrier électronique
				une copie du ou des cookie(s) déposés sur votre terminal à
				l&apos;adresse suivante : {' '}
				<a href={`mailto:${appLegalEmail}`}>
					{appLegalEmail}
				</a>
			</p>
			<p>
				Vous pouvez vous opposer au dépôt de cookies qui vous est adressé en utilisant
				les paramètres appropriés de votre navigateur concernant les cookies, le mode
				navigation privée ou encore le paramétrage « Do Not Track » de votre navigateur.
			</p>
			<p>
				Selon votre navigateur, vous disposez des options suivantes : accepter ou rejeter
				les cookies de toute origine ou d’une provenance donnée ou encore programmer
				l’affichage d’un message vous demandant votre accord à chaque fois qu’un cookie
				est déposé sur votre terminal.
			</p>
		</LegalArticle>
	</LegalSection>
);

PolitiqueCookies.propTypes = {
	appLegalEmail: PropTypes.string.isRequired,
	appUrl: PropTypes.string.isRequired,
	appWWW: PropTypes.string.isRequired,
};

export default PolitiqueCookies;
