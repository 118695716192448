import React from 'react';
import {
	HashRouter,
	Redirect,
	Route,
	Switch,
} from 'react-router-dom';
import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { createLogger } from 'redux-logger';

import PageNotFound from '@cecaz-immo/app-client/dist/pages/404';

import CookiesBanner from '@cecaz-immo/ui/dist/components/cookies/banner';
import Footer from '@cecaz-immo/app-client/dist/components/footer/footer';
import ScrollToTop from '@cecaz-immo/ui/dist/components/scroll-to-top/scroll-to-top';

import { RouteIfHasRole } from './components/security/security';
import HeaderSession from './components/header/session';
import RedirectClient from './components/redirect/client';

import NavDeveloper from './components/nav/developer/developer';

import PageAccount from './pages/account';
import PageAuthSignIn from './pages/auth/sign-in';
import PageAuthForgotPassword from './pages/auth/forgot-password';
import PageAuthResetPassword from './pages/auth/reset-password';
import PageCGU from './pages/cgu';
import PageContact from './pages/contact/contact';
import PageCookies from './pages/cookies';
import PageForbidden from './pages/forbidden';
import PageHelp from './pages/help';
import PageHome from './pages/home/home';
import PageLegal from './pages/legal';
import PageProgramme from './pages/programme/programme';
import PageProgrammeEditEdit from './pages/programme/edit';

import { ROLE_DEVELOPER } from './lib/roles';
import Reducers from './store/reducers';
import { connect } from './lib/session';

const logger = createLogger({
	// predicate: (getState, action) => action.type !== '',
	collapsed: (getState, action) => action.type !== '',
});

let middleware = [thunkMiddleware];

if (process.env.NODE_ENV !== 'production') {
	middleware = [...middleware, logger];
}

const store = createStore(Reducers, applyMiddleware(...middleware));
connect(store);

const DeveloperPageAuthForgotPassword = props => <PageAuthForgotPassword developer {...props} />;
const DeveloperPageAuthResetPassword = props => <PageAuthResetPassword developer {...props} />;
const DeveloperPageAuthResetPasswordWithCode = props => (
	<DeveloperPageAuthResetPassword withCode {...props} />);
const DeveloperPageAuthSignIn = props => <PageAuthSignIn developer {...props} />;

const App = () => (
	<CookiesProvider>
		<Provider store={store}>
			<HashRouter>
				<ScrollToTop>
					<div className={`wrapper developer ${global.isIEcancer ? 'ie' : ''}`}>
						<CookiesBanner />
						<Route component={HeaderSession} />
						<Route component={NavDeveloper} />
						<Switch>
							<Route
								path="/auth/signin"
								component={DeveloperPageAuthSignIn}
							/>
							<Route
								path="/auth/forgot-password/reset-password/:username"
								render={DeveloperPageAuthResetPasswordWithCode}
							/>
							{ /* redirect previous route (missing '/auth/') */ }
							<Route
								path="/forgot-password/reset-password/:username/:name"
								render={({ match }) => {
									const { username } = match.params;
									return (
										<Redirect to={`/auth/forgot-password/reset-password/${username}`} />
									);
								}}
							/>
							<Route
								path="/auth/forgot-password/"
								component={DeveloperPageAuthForgotPassword}
							/>
							<Route
								path="/auth/reset-password/:username/:temporaryPassword/:name"
								component={DeveloperPageAuthResetPassword}
							/>
							{ /* redirect previous route (missing '/auth/') */ }
							<Route
								path="/reset-password/:username/:temporaryPassword/:name"
								render={({ match }) => {
									const { username, temporaryPassword, name } = match.params;
									return (
										<Redirect to={`/auth/reset-password/${username}/${temporaryPassword}/${name}`} />
									);
								}}
							/>
							{ /* new route with encoded params in base64 */ }
							<Route
								path="/finalize/:credentials"
								component={DeveloperPageAuthResetPassword}
							/>
							<Route path="/forbidden" component={PageForbidden} />
							<Route path="/help" component={PageHelp} />
							<RouteIfHasRole
								exact
								path="/programme/edit/:uuid?"
								component={PageProgrammeEditEdit}
								role={ROLE_DEVELOPER}
							/>
							<RouteIfHasRole
								exact
								path="/programme/:name/p/:uuid"
								component={PageProgramme}
								role={ROLE_DEVELOPER}
							/>
							<RouteIfHasRole path="/contact" component={PageContact} role={ROLE_DEVELOPER} />
							<RouteIfHasRole path="/account" component={PageAccount} role={ROLE_DEVELOPER} />
							<RouteIfHasRole path="/conditions-generales-utilisation" component={PageCGU} role={ROLE_DEVELOPER} />

							<Route path="/politique-cookies" component={PageCookies} />
							<Route path="/mentions-legales" component={PageLegal} />
							{ /* redirect to client */}
							<RedirectClient path="/loi-pinel" />
							<RedirectClient path="/pret-taux-zero" />
							<RouteIfHasRole
								exact
								path="/"
								component={PageHome}
								role={ROLE_DEVELOPER}
							/>
							<Route path="*" component={PageNotFound} />
						</Switch>
						<Footer />
					</div>
				</ScrollToTop>
			</HashRouter>
		</Provider>
	</CookiesProvider>
);

export default App;
