import {
	EDIT_DEVELOPER_UPDATE_DEVELOPER,
} from '../actions/edit-developer';

const defaultState = {};
export default (state = defaultState, action) => {
	switch (action.type) {
	case EDIT_DEVELOPER_UPDATE_DEVELOPER:
		return Object.assign({}, state, action.developer);
	default:
		return state;
	}
};
