
import PropTypes from 'prop-types';
import React from 'react';

import Form from '@cecaz-immo/ui/dist/components/form/form';

import './form.scss';

const AuthForm = ({ children, className, ...rest }) => (
	<Form
		className={`AuthForm form${className ? ` ${className}` : ''}`}
		{...rest}
	>
		{children}
	</Form>
);

AuthForm.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

AuthForm.defaultProps = {
	children: null,
	className: '',
};

export default AuthForm;
