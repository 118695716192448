import PropTypes from 'prop-types';
import React from 'react';

import './group.scss';

const Group = ({ className, children }) => (
	<div className={`FormGroup ${className}`}>
		{children}
	</div>
);

Group.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

Group.defaultProps = {
	children: null,
	className: '',
};

export default Group;
