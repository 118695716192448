import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

import Nav from '@cecaz-immo/ui/dist/components/nav/nav';
import NavItem from '@cecaz-immo/ui/dist/components/nav/nav-item';

const NavDeveloper = (props) => {
	const { location, session } = props;
	const current = location.pathname.split('/')[1];

	return (
		<>
			{
				session && session.valid
				&& (
					<Nav>
						<NavItem current={current} id="" label="Mes Programmes" link="/" />
						<NavItem current={current} id="contact" label="Contact" link="/contact" />
						<NavItem current={current} id="account" label="Mon compte" link="/account" />
						<NavItem current={current} id="help" label="Aide" link="/help" />
					</Nav>
				)
			}
		</>
	);
};

NavDeveloper.propTypes = {
	location: PropTypes.shape({}).isRequired,
	session: PropTypes.shape({}),
};

NavDeveloper.defaultProps = {
	session: {},
};

const mapStateToProps = (state) => ({
	session: state.session,
});

export default connect(mapStateToProps)(NavDeveloper);
