export const TO_CORRECT = 'TO_CORRECT';
export const WAITING = 'WAITING';
export const PUBLISHED = 'PUBLISHED';
export const FINISHED = 'FINISHED';

export const labels = {
	[TO_CORRECT]: 'À corriger',
	[WAITING]: 'En attente de validation',
	[PUBLISHED]: 'Publié',
	[FINISHED]: 'Terminé',
};
