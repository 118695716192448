import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';

import './banner.scss';

class CookiesBanner extends React.Component {
	constructor(props) {
		super(props);
		const { cookies } = this.props;
		const cookiePolicyValidated = cookies.get('cookie-policy-validated') || false;
		this.state = { cookiePolicyValidated };
		this.validateCookiePolicy = this.validateCookiePolicy.bind(this);
	}

	validateCookiePolicy(event) {
		event.preventDefault();
		const { cookies } = this.props;

		const cookiePolicyValidated = true;
		cookies.set('cookie-policy-validated', cookiePolicyValidated, { path: '/' });
		this.setState({ cookiePolicyValidated });
	}

	render() {
		const { cookiePolicyValidated } = this.state;
		const hidden = cookiePolicyValidated ? 'hidden' : '';
		return (
			!cookiePolicyValidated
			&& (
				<div className={`CookiesBanner ${hidden}`}>
					<p>
						En poursuivant votre navigation sur ce site,
						vous acceptez { ' ' }
						<Link to="/politique-cookies">l’utilisation de cookies ou autres traceurs</Link> { ' ' }
							et les <Link to="/conditions-generales-utilisation"> les Conditions Générales d’Utilisation</Link>.{ ' ' }
						<button type="button" className="CookiesBanner-validate" onClick={this.validateCookiePolicy}>J&apos;accepte</button>
					</p>
				</div>
			)
		);
	}
}

CookiesBanner.propTypes = {
	cookies: PropTypes.shape({
		get: PropTypes.func,
		set: PropTypes.func,
	}),
};

CookiesBanner.defaultProps = {
	cookies: {},
};

export default withCookies(CookiesBanner);
