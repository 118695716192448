import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import PageContent from '@cecaz-immo/ui/dist/components/page/content';

import DeveloperEdit from '../components/developer/edit/edit';
import UserEditPassword from '../components/user/edit/password';
import {
	requestGetEditDeveloperFromCurrentUserAction,
	updateEditDeveloperAction,
	requestSaveEditDeveloperAction,
} from '../store/actions/edit-developer';

class Account extends React.Component {
	constructor(props) {
		super(props);

		const { getDeveloper } = props;

		// eslint-disable-next-line no-console
		getDeveloper().catch(console.error);
	}

	render() {
		const {
			developer,
			lp,
			updateDeveloper,
			saveDeveloper,
		} = this.props;
		return (
			<PageContent className="PageAccount">
				<section className="section page-center">
					<h3 className="section-title">Modifier mes informations</h3>
					<DeveloperEdit
						developer={developer}
						myAccount
						lp={lp}
						updateDeveloper={updateDeveloper}
						saveDeveloper={saveDeveloper}
					/>
				</section>
				<section className="section page-center last-section">
					<h3 className="section-title">Modifier mon mot de passe</h3>
					<UserEditPassword user={developer} />
				</section>
			</PageContent>
		);
	}
}

Account.propTypes = {
	developer: PropTypes.shape({
		id: PropTypes.number,
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		tradingName: PropTypes.string,
		phone: PropTypes.string,
	}),
	getDeveloper: PropTypes.func.isRequired,
	lp: PropTypes.bool,
	saveDeveloper: PropTypes.func.isRequired,
	updateDeveloper: PropTypes.func.isRequired,
};

Account.defaultProps = {
	developer: {},
	lp: false,
};

const mapStateToProps = (state) => ({
	developer: state.editDeveloper,
});

const mapDispatchToProps = (dispatch) => ({
	getDeveloper: () => dispatch(requestGetEditDeveloperFromCurrentUserAction()),
	saveDeveloper: () => dispatch(requestSaveEditDeveloperAction()),
	updateDeveloper: (developer) => dispatch(updateEditDeveloperAction(developer)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Account);
