import {
	CognitoUserPool,
	CognitoUser,
	AuthenticationDetails,
} from 'amazon-cognito-identity-js';

import './aws';

const {
	REACT_APP_AWS_COGNITO_CLIENT_ID,
	REACT_APP_AWS_COGNITO_USER_POOL_ID,
} = process.env;

//Connection
const UserPool = new CognitoUserPool({
	ClientId: REACT_APP_AWS_COGNITO_CLIENT_ID,
	UserPoolId: REACT_APP_AWS_COGNITO_USER_POOL_ID,
});

export class NewPasswordRequiredError {
	constructor(userAttributes) {
		this.userAttributes = userAttributes;
	}
}

const initCognitoUser = (credentials) => {
	const { password } = credentials;
	let { username } = credentials;
	username = (username || '').toLowerCase();
	const result = {};

	result.cognitoUser = new CognitoUser({
		Username: username,
		Pool: UserPool,
	});

	if (password) {
		result.authenticationDetails = new AuthenticationDetails({
			Username: username,
			Password: password,
		});
	}

	return result;
};

const defaultSession = {
	cognitoUser: null,
	cognitoSession: null,
	cognitoUserAttributes: null,
	decodedIdToken: null,
};

const _session = {};

export const resetSession = () => {
	Object.assign(_session, defaultSession);
};

export const getCognitoUser = () => new Promise((resolve) => {
	_session.cognitoUser = _session.cognitoUser || UserPool.getCurrentUser();
	if (!_session.cognitoUser) {
		throw new Error('User is not connected.');
	}
	resolve(_session.cognitoUser);
});

export const getSession = () => getCognitoUser()
	.then(cognitoUser => new Promise((resolve, reject) => {
		if (_session.cognitoSession && _session.cognitoSession.isValid()) {
			resolve(_session.cognitoSession);
			return;
		}

		cognitoUser.getSession((err, cognitoSession) => {
			if (err) {
				reject(err);
				return;
			}

			if (!cognitoSession.isValid()) {
				throw new Error('Session invalid.');
			}

			_session.cognitoSession = cognitoSession;

			resolve(cognitoSession);
		});
	}))
	.catch((error) => {
		resetSession();
		throw error;
	});

export const getUserAttributes = () => getSession()
	.then(() => new Promise((resolve, reject) => {
		if (_session.cognitoUserAttributes) {
			resolve(_session.cognitoUserAttributes);
			return;
		}

		_session.cognitoUser
			.getUserAttributes((error, attributes) => {
				if (error) {
					reject(error);
					return;
				}

				const cognitoUserAttributes = {};
				attributes.forEach(({ Name, Value }) => {
					cognitoUserAttributes[Name] = Value;
				});

				_session.cognitoUserAttributes = cognitoUserAttributes;

				resolve(cognitoUserAttributes);
			});
	}));

export const getDecodedIdToken = () => getSession()
	.then(cognitoSession => new Promise((resolve) => {
		if (_session.decodedIdToken) {
			resolve(_session.decodedIdToken);
			return;
		}

		const jwtToken = cognitoSession.getIdToken().getJwtToken();
		const jwtPayload = jwtToken.split('.')[1];
		const decodedIdToken = JSON.parse(atob(jwtPayload).toString('utf8'));

		_session.decodedIdToken = decodedIdToken;

		resolve(decodedIdToken);
	}));

export const getFullSession = () => getCognitoUser()
	.then(() => getSession())
	.then(() => getDecodedIdToken())
	.then(() => getUserAttributes())
	.then(() => _session);

export const signIn = (username, password) => {
	resetSession();
	return new Promise((resolve, reject) => {
		const {
			cognitoUser,
			authenticationDetails,
		} = initCognitoUser({ username, password });

		cognitoUser.authenticateUser(authenticationDetails, {
			onSuccess: (result) => {
				resolve(result);
			},
			onFailure: reject,
			newPasswordRequired: (userAttributes) => {
				reject(new NewPasswordRequiredError(userAttributes));
			},
		});
	});
};

export const signOut = () => getCognitoUser()
	.then((cognitoUser) => {
		cognitoUser.signOut();
		resetSession();
		return cognitoUser;
	});

export const completeNewPasswordChallenge = (username, password, newPassword) => {
	resetSession();
	return new Promise((resolve, reject) => {
		const {
			cognitoUser,
			authenticationDetails,
		} = initCognitoUser({ username, password });

		cognitoUser.authenticateUser(authenticationDetails, {
			onSuccess: () => Promise.reject(new Error('Password challenge already completed')),
			onFailure: reject,
			onMFARequired: reject,
			newPasswordRequired: () => {
				cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
					onSuccess: (result) => {
						resolve(result);
					},
					onFailure: reject,
				});
			},
		});
	});
};

export const confirmResetPassword = (username, password, code) => new Promise((resolve, reject) => {
	const { cognitoUser } = initCognitoUser({ username });

	cognitoUser.confirmPassword(code, password, {
		onSuccess: resolve,
		onFailure: reject,
	});
});

export const changePassword = (username, oldPassword, newPassword) =>
	new Promise((resolve, reject) => {
		const {
			cognitoUser,
			authenticationDetails,
		} = initCognitoUser({ username, password: oldPassword });

		cognitoUser.authenticateUser(authenticationDetails, {
			onSuccess: () => {
				cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
					if (err) {
						reject(err);
						return;
					}

					resolve(result);
				});
			},
			onFailure: reject,
		});
	});
