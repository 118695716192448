import React, { useState, useEffect } from 'react';
import { Field, FieldError } from 'react-jsonschema-form-validation';
import PropTypes from 'prop-types';
import {
	Col,
	Label,
	FormGroup,
	Input,
	CustomInput,
	Button,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
} from 'reactstrap';

import Select from '@cecaz-immo/ui/dist/components/form/select/Simple';

import getInputValue from '../../../../lib/input-value';

import './lot-item.scss';

const propertyTypeOption = [
	{ value: 'FLAT', label: 'Appartement' },
	{ value: 'HOUSE', label: 'Maison' },
	{ value: 'PLOT', label: 'Terrain bâti' },
	{ value: 'COMMERCIAL', label: 'Local commercial' },
];

const MAX_ROOMS = 8;
const calculateRoomsOptions = () => {
	const result = [];

	for (let i = 1; i <= MAX_ROOMS; i += 1) {
		result.push({
			value: i,
			label: i,
		});
	}

	return result;
};

const ProgrammeEditTabsLotItem = ({
	className,
	createLotProgramme,
	deleteLotProgramme,
	index,
	lot,
	updateLotProgramme,
}) => {
	const [lotData, setLotData] = useState({
		amount: '',
		containParking: false,
		maxArea: '',
		maxPrice: '',
		minArea: '',
		minPrice: '',
		rooms: '',
		type: '',
	});

	useEffect(() => {
		setLotData({
			amount: lot.amount,
			containParking: lot.containParking,
			maxArea: lot.maxArea,
			maxPrice: lot.maxPrice,
			minArea: lot.minArea,
			minPrice: lot.minPrice,
			rooms: lot.rooms,
			type: lot.type,
		});
	}, [lot]);

	const onClickDuplicate = () => {
		const newLot = { ...lot || lotData };
		createLotProgramme(newLot);
	};

	const onClickDelete = () => {
		deleteLotProgramme(index);
	};

	const handleInputChange = (e) => {
		const { target } = e;
		const { name } = target;
		let { value } = target;

		switch (target.type) {
		case 'checkbox':
			value = target.checked;
			break;
		case 'number': {
			const f = parseFloat(value, 10);
			value = f === 0 ? 0 : (f || undefined);
			break;
		}
		default:
		}

		if (!lot) {
			setLotData({
				[name]: value,
			});
		}

		if (updateLotProgramme) {
			updateLotProgramme(index, {
				[name]: value,
			});
		}
	};

	const handleSelectChange = ({ target }) => {
		if (!lot) {
			setLotData({
				[target.name]: getInputValue(target.value),
			});
		}

		if (updateLotProgramme) {
			updateLotProgramme(index, {
				[target.name]: getInputValue(target.value),
			});
		}
	};

	const fieldnamePrefix = !Number.isNaN(index) ? `lot-${index}-` : '';

	const customLotErrorMsg = {
		required: () => 'requis',
		minimum: () => 'valeur incorrecte',
		maximun: () => 'valeur incorrecte',
		pattern: () => 'valeur incorrecte',
		type: () => 'La vauleur doit être un nombre entier',
	};

	return (
		<fieldset className={`${className || ''} ProgrammeEditTabsLotItem border`}>
			<legend className="w-auto ml-2"><h3>Lot n°{index + 1}</h3></legend>
			<Col xs="12" className="d-flex flex-column flex-md-row align-items-start">
				<FormGroup className="mr-0 mr-md-4">
					<Label>Type</Label>
					<Field
						className=""
						clearable={false}
						component={Select}
						name="type"
						onChange={handleSelectChange}
						options={propertyTypeOption}
						value={lotData.type}
					/>
					<FieldError
						name={`lots.${index}.type`}
						errorMessages={customLotErrorMsg}
					/>
				</FormGroup>
				<FormGroup className="mr-0 mr-md-4">
					<Label>Pièces</Label>
					<Field
						className="select-sm"
						clearable={false}
						component={Select}
						name="rooms"
						onChange={handleSelectChange}
						options={calculateRoomsOptions()}
						style={{ width: '100px' }}
						value={lotData.rooms}
					/>
					<FieldError name={`lots.${index}.rooms`} errorMessages={customLotErrorMsg} />
				</FormGroup>
				<FormGroup className="mr-0 mr-md-4">
					<Label>Quantité</Label>
					<Field
						component={Input}
						type="number"
						name="amount"
						fieldname={`${fieldnamePrefix}amount`}
						min="0"
						placeholder="Qté"
						className="field number"
						value={lotData.amount}
						onChange={handleInputChange}
					/>
					<FieldError name={`lots.${index}.amount`} errorMessages={customLotErrorMsg} />
				</FormGroup>
			</Col>
			<Col xs="12">
				<div className="d-flex flex-wrap">
					<FormGroup className="mr-0 mr-sm-4">
						<Label>Surface min</Label>
						<Field
							component={Input}
							name="minArea"
							type="number"
							fieldname={`${fieldnamePrefix}minArea`}
							placeholder="Min"
							min="0"
							className=""
							value={lotData.minArea}
							onChange={handleInputChange}
						/>
						<FieldError name={`lots.${index}.minArea`} errorMessages={customLotErrorMsg} />
					</FormGroup>
					<FormGroup>
						<Label>Surface max</Label>
						<Field
							className=""
							component={Input}
							fieldname={`${fieldnamePrefix}maxArea`}
							min="0"
							name="maxArea"
							onChange={handleInputChange}
							placeholder="Max"
							type="number"
							value={lotData.maxArea}
						/>
						<FieldError name={`lots.${index}.maxArea`} errorMessages={customLotErrorMsg} />
					</FormGroup>
				</div>
			</Col>
			<Col xs="12">
				<div className="d-flex flex-wrap">
					<FormGroup className="mr-0 mr-md-4">
						<Label>Prix min</Label>
						<InputGroup>
							<Field
								component={Input}
								name="minPrice"
								type="number"
								placeholder="Min"
								fieldname={`${fieldnamePrefix}minPrice`}
								className="bignumber"
								value={lotData.minPrice}
								onChange={handleInputChange}
								min="0"
								step="1000"
							/>
							<InputGroupAddon addonType="append">
								<InputGroupText>€</InputGroupText>
							</InputGroupAddon>
						</InputGroup>
						<FieldError name={`lots.${index}.minPrice`} errorMessages={customLotErrorMsg} />
					</FormGroup>
					<FormGroup>
						<Label>Prix max</Label>
						<InputGroup>
							<Field
								component={Input}
								name="maxPrice"
								type="number"
								placeholder="Max"
								fieldname={`${fieldnamePrefix}maxPrice`}
								className="field number bignumber"
								value={lotData.maxPrice}
								onChange={handleInputChange}
								min="0"
								step="1000"
							/>
							<InputGroupAddon addonType="append">
								<InputGroupText>€</InputGroupText>
							</InputGroupAddon>
						</InputGroup>
						<FieldError name={`lots.${index}.maxPrice`} errorMessages={customLotErrorMsg} />
					</FormGroup>
				</div>
			</Col>
			<Col>
				<FormGroup>
					<Label htmlFor={`containParking-${index}`}>Parking</Label>
					<div className="d-flex align-items-center">
						<Field
							checked={lotData.containParking}
							className="field"
							component={CustomInput}
							id={`containParking-${index}`}
							name="containParking"
							onChange={handleInputChange}
							type="checkbox"
						/>
						<Label htmlFor={`containParking-${index}`}>{lotData.containParking ? 'oui' : 'non'}</Label>
					</div>
				</FormGroup>
			</Col>
			<div className="actions d-flex pr-4">
				<FormGroup className="d-flex flex-column mr-4">
					<Label>Dupliquer</Label>
					<Button
						className="btn"
						size="sm"
						onClick={onClickDuplicate}
						outline
						title="Dupliquer le lot"
					>
						<i className="fa fa-clone center field icon" />
					</Button>
				</FormGroup>
				<FormGroup className="d-flex flex-column">
					<Label>Supprimer</Label>
					<Button
						className="btn"
						size="sm"
						color="danger"
						onClick={onClickDelete}
						outline
						title="Supprimer le lot"
					>
						<i className="fa fa-trash center field icon" />
					</Button>
				</FormGroup>
			</div>
		</fieldset>
	);
};

ProgrammeEditTabsLotItem.propTypes = {
	className: PropTypes.string,
	lot: PropTypes.shape({
		amount: PropTypes.number,
		containParking: PropTypes.bool,
		maxArea: PropTypes.number,
		maxPrice: PropTypes.number,
		minArea: PropTypes.number,
		minPrice: PropTypes.number,
		rooms: PropTypes.number,
		type: PropTypes.string,
	}),
	index: PropTypes.number,
	deleteLotProgramme: PropTypes.func,
	createLotProgramme: PropTypes.func.isRequired,
	updateLotProgramme: PropTypes.func,
};

ProgrammeEditTabsLotItem.defaultProps = {
	className: '',
	deleteLotProgramme: null,
	index: 0,
	lot: {},
	updateLotProgramme: null,
};

export default ProgrammeEditTabsLotItem;
