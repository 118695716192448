function getInputValue(target) {
	switch (target.type) {
	case 'number':
		return target.value !== '' ? +target.value : '';
	case 'checkbox':
		return target.checked;
	case 'file':
		// eslint-disable-next-line no-nested-ternary
		return target.value !== '' ? (target.multiple ? Array.from(target.files) : target.files[0]) : '';
	default:
		return target.value;
	}
}

export default getInputValue;
