import PropTypes from 'prop-types';
import React from 'react';

import PageContent from '@cecaz-immo/ui/dist/components/page/content';

import AuthFormsSignIn from '../../components/auth/forms/sign-in';

const SignIn = ({ developer, history, lp }) => (
	<PageContent className="PageAuthSignIn">
		<div className="section last-section">
			<header>
				<h1 className="section-title">
					{/* eslint-disable-next-line no-nested-ternary */}
					Espace { developer ? (lp ? 'Partenaire' : 'Promoteur') : 'Administrateur' }
				</h1>
			</header>
			<div className="page-center">
				<AuthFormsSignIn history={history} />
			</div>
		</div>
	</PageContent>
);

SignIn.propTypes = {
	developer: PropTypes.bool,
	history: PropTypes.shape({}).isRequired,
	lp: PropTypes.bool,
};

SignIn.defaultProps = {
	developer: false,
	lp: false,
};

export default SignIn;
