import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import './modal.scss';

ReactModal.setAppElement('#root');

const Modal = ({
	children,
	className,
	title,
	...rest
}) => (
	<ReactModal
		className={`Modal ${className}`}
		overlayClassName="ModalOverlay"
		{...rest}
	>
		<div className="Modal-box">
			{
				title
				&& (
					<header className="Modal-header">
						<h3 className="Modal-title">{title}</h3>
					</header>
				)
			}
			<div className="Modal-content">
				{children}
			</div>
		</div>
	</ReactModal>
);

Modal.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	title: PropTypes.node,
};

Modal.defaultProps = {
	children: null,
	className: '',
	title: '',
};

export default Modal;
