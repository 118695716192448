import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PageContent from '@cecaz-immo/ui/dist/components/page/content';

import { getAdminByIdAction } from '../../store/actions/admin';
import { getCurrentDeveloperAction } from '../../store/actions/developer';

import './contact.scss';

class Contact extends React.Component {
	constructor(props) {
		super(props);

		props.getCurrentDeveloper()
			.catch((err) => {
				// eslint-disable-next-line no-console
				console.error(err);
				global.rollbar.error('Contact getCurrentDeveloper', err);
			});
	}

	componentWillReceiveProps(nextProps) {
		if (!nextProps.admin.id && nextProps.developer) {
			const { getAdminById } = this.props;
			getAdminById(nextProps.developer.adminId)
				.catch((err) => { console.error(err); });
		}
	}

	render() {
		let { admin } = this.props;
		admin = admin || {};
		return (
			<PageContent className="PageContact">
				<section className="page-center section last-section">
					<h1 className="section-title">Contactez votre conseiller</h1>
					<ul className="list-undecorated">
						<li>
							<span>Nom:</span>
							<p>{admin.lastName}</p>
						</li>
						<li>
							<span>Prénom:</span>
							<p>{admin.firstName}</p>
						</li>
						<li>
							<span>Téléphone:</span>
							<p>{admin.phone}</p>
						</li>
						<li>
							<span>Email:</span>
							<p>{admin.email}</p>
						</li>
					</ul>
				</section>
			</PageContent>
		);
	}
}

Contact.propTypes = {
	admin: PropTypes.shape({ id: PropTypes.number }),
	developer: PropTypes.shape({ adminId: PropTypes.number }),
	getAdminById: PropTypes.func.isRequired,
	getCurrentDeveloper: PropTypes.func.isRequired,
};

Contact.defaultProps = {
	admin: {},
	developer: {},
};

const mapStateToProps = state => ({
	admin: state.admin,
	developer: state.developer,
});

const mapDispatchToProps = dispatch => ({
	getAdminById: adminId => dispatch(getAdminByIdAction(adminId)),
	getCurrentDeveloper: () => dispatch(getCurrentDeveloperAction()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Contact);
