import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import logoCecaz from '@cecaz-immo/app-client/dist/images/logo-ce.png';
import AdminHeader from '@cecaz-immo/ui/dist/components/admin/header/header';

import { signOut } from '../../lib/session';

import './session.scss';

class HeaderSession extends React.Component {
	constructor(props) {
		super(props);

		this.onSignOut = this.onSignOut.bind(this);
	}

	onSignOut() {
		signOut();

		const { history } = this.props;
		history.push('/auth/signin');
	}

	render() {
		const { session, ...rest } = this.props;

		return (
			<AdminHeader logo={logoCecaz} {...rest}>
				{
					session && session.valid
					&& (
						<section className="Header-session">
							<div className="account">
								<i className="fa fa-user" aria-hidden="true" />
								<Link to="/account">
									<div className="account-name">
										<span>{session.attributes.given_name}</span>
										<span>{session.attributes.family_name}</span>
									</div>
								</Link>
							</div>
							<Button
								outline
								color="primary"
								onClick={this.onSignOut}
							>
								Se déconnecter
							</Button>
						</section>
					)
				}
			</AdminHeader>
		);
	}
}

HeaderSession.propTypes = {
	session: PropTypes.shape({}),
	history: PropTypes.shape({ push: PropTypes.func }),
};

HeaderSession.defaultProps = {
	session: {},
	history: {},
};

const mapStateToProps = state => ({
	session: state.session,
});

export default connect(mapStateToProps)(HeaderSession);
