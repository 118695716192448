const Jimp = require('jimp');

const defaults = {
	background: 0xFFFFFFFF,
	resizeFunction: 'cover',
	quality: 70,
	height: 1048,
	width: 1920,
	mimeType: Jimp.MIME_JPEG,
};

export function read(file) {
	return new Promise((resolve, reject) => {
		const fr = new FileReader();
		fr.addEventListener('error', reject, false);
		fr.addEventListener('load', function onLoad() {
			resolve(this.result);
		}, false);
		return fr.readAsArrayBuffer(file);
	});
}

export function resize(path, options) {
	const settings = Object.assign({}, defaults, options);
	const getFunction = settings.buffer ? 'getBuffer' : 'getBase64';

	return new Promise((resolve, reject) => {
		Jimp.read(path, (err, image) => {
			if (err) {
				reject(err);
				return;
			}

			image
				.background(settings.background)
				// eslint-disable-next-line no-unexpected-multiline
				[settings.resizeFunction](settings.width, settings.height)
				.quality(settings.quality)
				// eslint-disable-next-line no-unexpected-multiline
				[getFunction](settings.mimeType, (error, result) => {
					if (error) throw error;

					resolve(result);
				});
		});
	});
}
