import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './nav-item.scss';

const NavItem = ({
	current,
	id,
	label,
	link,
}) => (
	<li className={`NavItem ${current === id ? 'active' : ''}`}>
		<Link to={link}>{label}</Link>
	</li>
);

NavItem.propTypes = {
	current: PropTypes.string,
	id: PropTypes.string.isRequired,
	label: PropTypes.node.isRequired,
	link: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
	current: '',
};

export default NavItem;
