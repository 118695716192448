import { fetchWithSession } from '../../lib/session';
import { sendFile } from '../../lib/upload-file';
import { FINISHED } from '../../lib/status';

const { REACT_APP_API_URL } = process.env;

export const EDIT_PROGRAMME_SAVE_PREVIOUS_PROGRAMME = 'EDIT_PROGRAMME_SAVE_PREVIOUS_PROGRAMME';
export const EDIT_PROGRAMME_CREATE_ACTION = 'EDIT_PROGRAMME_CREATE_ACTION';
export const EDIT_PROGRAMME_UPDATE_ACTION = 'EDIT_PROGRAMME_UPDATE_ACTION';
export const EDIT_PROGRAMME_DELETE_ACTION = 'EDIT_PROGRAMME_DELETE_ACTION';
export const EDIT_PROGRAMME_LOT_UPDATE_ACTION = 'EDIT_PROGRAMME_LOT_UPDATE_ACTION';
export const EDIT_PROGRAMME_LOT_CREATE_ACTION = 'EDIT_PROGRAMME_LOT_CREATE_ACTION';
export const EDIT_PROGRAMME_LOT_DELETE_ACTION = 'EDIT_PROGRAMME_LOT_DELETE_ACTION';
export const EDIT_PROGRAMME_FILES_UPDATE_ACTION = 'EDIT_PROGRAMME_FILES_UPDATE_ACTION';

export const createProgramme = () => ({
	type: EDIT_PROGRAMME_CREATE_ACTION,
});

export const deleteProgrammeAction = (programme) => ({
	type: EDIT_PROGRAMME_DELETE_ACTION,
	programme,
});

export const updateEditProgrammeAction = (programme) => ({
	type: EDIT_PROGRAMME_UPDATE_ACTION,
	programme,
});

export const updateLotProgramme = (index, lot) => ({
	type: EDIT_PROGRAMME_LOT_UPDATE_ACTION,
	index,
	lot,
});

export const createLotProgramme = (lot) => ({
	type: EDIT_PROGRAMME_LOT_CREATE_ACTION,
	lot,
});

export const deleteLotProgramme = (index) => ({
	type: EDIT_PROGRAMME_LOT_DELETE_ACTION,
	index,
});

export const updateFiles = (files) => ({
	type: EDIT_PROGRAMME_FILES_UPDATE_ACTION,
	files,
});

const transformFilesToFilesDescriptions = (files) => {
	const results = [];

	if (!files || !files.length) {
		return results;
	}

	files.forEach((f) => {
		if (f.id) {
			results.push(f);
			return;
		}

		const newFile = {
			order: f.order,
			type: f.type,
			name: f.name,
		};

		results.push(newFile);
	});

	return results;
};

export const deleteProgramme = (programme) => (dispatch) => fetchWithSession(`${REACT_APP_API_URL}/programme/${programme.uuid}/status`, {
	headers: {
		'Content-Type': 'application/json',
	},
	method: 'PUT',
	body: JSON.stringify({ status: FINISHED }),
})
	.then(() => { dispatch(deleteProgrammeAction(programme)); })
	.catch((e) => {
		// eslint-disable-next-line no-console
		console.log('error delete programme ', e);
		global.rollbar.error('deleteProgramme', e);
	});

export const getCurrentProgrammeToEdit = (uuid) => (dispatch) => fetchWithSession(`${REACT_APP_API_URL}/programme/${uuid}/latest`, {
	headers: {
		'Cache-Control': 'no-cache',
	},
})
	.then((resp) => resp.json())
	.then((result) => {
		dispatch(updateEditProgrammeAction(result));
	});

export const putCurrentProgramme = () => (dispatch, getState) => {
	const programme = getState().editProgramme;
	let request = '';
	let method = '';
	if (!programme.uuid) {
		request = `${REACT_APP_API_URL}/programme`;
		method = 'POST';
	} else {
		request = `${REACT_APP_API_URL}/programme/${programme.uuid}`;
		method = 'PUT';
	}

	const { files } = programme;
	programme.files = transformFilesToFilesDescriptions(files);
	programme.floors = Number.parseInt(programme.floors, 10);
	delete programme.minPrice;
	delete programme.maxPrice;
	delete programme.maxRooms;
	delete programme.date;
	delete programme.minPrice;
	delete programme.minRooms;
	return fetchWithSession(request, {
		headers: {
			'Content-Type': 'application/json',
		},
		method,
		body: JSON.stringify(programme),
	})
		.then((resp) => {
			if (!resp.ok) {
				return Promise.reject(resp.json());
			}
			return resp;
		})
		.then((resp) => (resp.json()))
		.then((resp) => {
			if (!files || !files.length) {
				return resp;
			}

			const promises = [];

			//Send new files in S3
			files.forEach((file, index) => {
				if (!file.id) {
					promises.push(sendFile(resp.files[index].uploadUrl, file.blob));
				}
			});
			return Promise.all(promises)
				.then(() => resp);
		})
		.catch((e) => {
			// eslint-disable-next-line no-console
			console.error('error update programme ', e);
			global.rollbar.error('putCurrentProgramme', e);
		});
};


export const updateProgrammeStatus = (status, commentary) => (dispatch, getState) => {
	const programme = getState().editProgramme;

	return fetchWithSession(`${REACT_APP_API_URL}/programme/${programme.uuid}/status`, {
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'PUT',
		body: JSON.stringify({ status, commentary }),
	})
		.then((resp) => {
			if (!resp.ok) {
				return Promise.reject(resp.json());
			}
			return resp;
		})
		.catch((e) => {
			// eslint-disable-next-line no-console
			console.log('error update status programme ', e);
			global.rollbar.error('updateProgrammeStatus', e);
		});
};
