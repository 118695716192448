import { password } from './types';

const userSchema = {
	type: 'object',
	properties: {
		password,
		passwordConfirm: { type: 'string', const: { $data: '1/password' } },
		oldPassword: { type: 'string' },
	},
	required: [
		'password',
		'passwordConfirm',
		'oldPassword',
	],
};

export default userSchema;
