import React from 'react';

import PageContent from '@cecaz-immo/ui/dist/components/page/content';

const { history } = global;

export default () => (
	<PageContent className="PageForbidden">
		<div className="page-center section last-section">
			<p className="text-center">
				Vous n&apos;avez pas les autorisations nécessaires pour effectuer cette action.
			</p>
			<p className="text-center">
				<button onClick={() => { history.back(); }} className="btn" type="button">Retour</button>
			</p>
		</div>
	</PageContent>
);
