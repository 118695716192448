import React from 'react';
import PropTypes from 'prop-types';

// components
import FieldAdmin from '@cecaz-immo/ui/dist/components/form/field/admin';
import FormButton from '@cecaz-immo/ui/dist/components/form/button/button';
import AlertMessage from '../../alert-message/alert-message';
import AuthForm from '../form/form';

// schemas
import { resetPasswordSchema, resetPasswordWithCodeSchema } from '../../../schemas/auth';
// libs
import { completeNewPasswordChallenge, confirmResetPassword } from '../../../lib/session';

class AuthFormsResetPassword extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			display: false,
			title: '',
			message: '',
			success: false,
			password: '',
			passwordConfirm: '',
			code: '',
		};

		this.onSubmitResetPassword = this.onSubmitResetPassword.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	onSubmitResetPassword(e) {
		e.preventDefault();

		const { withCode } = this.props;
		const { password } = this.state;

		if (withCode) {
			const { code } = this.state;
			this.resetPasswordWithCode(password, code);
		} else {
			this.resetPassword(password);
		}
	}

	resetPassword(password) {
		const {
			history,
			temporaryPassword,
			username,
		} = this.props;

		this.setState({ loading: true });

		completeNewPasswordChallenge(username, temporaryPassword, password)
			.then(() => {
				history.push('/');
			})
			.catch((err) => {
				let title = '';
				let message = '';

				if (err.code === 'UserNotFoundException') {
					title = 'Erreur identifiant / mot de passe';
					message = 'Les identifiants saisis sont incorrects.';
				} else {
					title = 'Erreur système';
					message = 'Veuillez contacter un administrateur'
						+ ' pour obtenir plus de renseignements.';
				}

				this.setState({
					display: true,
					title,
					message,
					loading: false,
					success: false,
					password: '',
					passwordConfirm: '',
					code: '',
				});
			});
	}

	resetPasswordWithCode(password, code) {
		const {
			history,
			username,
		} = this.props;

		this.setState({ loading: true });

		confirmResetPassword(username, password, code.trim())
			.then(() => {
				history.push('/');
			})
			.catch((err) => {
				let title = '';
				let message = '';
				if (err.code === 'CodeMismatchException') {
					title = 'Erreur code de vérification';
					message = 'Le code de vérification que vous avez saisi n\'est pas correcte..';
				} else if (err.code === 'ExpiredCodeException') {
					title = 'Erreur code expiré';
					message = 'Le code de vérification que vous avez saisi est expiré... Veuillez refaire une demande.';
				} else {
					title = 'Erreur système';
					message = 'Veuillez contacter un administrateur pour plus de renseignement...';
				}

				this.setState({
					display: true,
					title,
					message,
					loading: false,
					success: false,
					password: '',
					passwordConfirm: '',
					code: '',
				});
			});
	}

	handleChange(data) {
		this.setState(data);
	}

	render() {
		const {
			name,
			withCode,
		} = this.props;

		const {
			code,
			display,
			loading,
			message,
			password,
			passwordConfirm,
			success,
			title,
		} = this.state;

		let formSchema;
		const formData = {
			password,
			passwordConfirm,
		};
		if (withCode) {
			formData.code = code;
			formSchema = resetPasswordWithCodeSchema;
		} else {
			formSchema = resetPasswordSchema;
		}

		return (
			<AuthForm
				className="reset-password connection-promoter form"
				data={formData}
				onChange={this.handleChange}
				onSubmit={this.onSubmitResetPassword}
				schema={formSchema}
			>
				<h3 className="title">
					{
						name
							? `Bonjour ${name}, veuillez saisir un nouveau mot de passe`
							: 'Veuillez saisir un nouveau mot de passe'
					}
				</h3>
				<p>
					Le mot de passe doit contenir au minimum 8 caractères dont: une
					<strong> majuscule</strong>, une<strong> minuscule</strong>, un
					<strong> caractère spécial</strong> et un <strong>chiffre</strong>.
				</p>
				<div className="row">
					<FieldAdmin
						component="input"
						type="password"
						name="password"
						className="field"
						placeholder="Nouveau mot de passe"
						value={password}
						errorMessages={{
							pattern: () => `Le mot de passe doit contenir :au minimum 8 caractères dont: une
majuscule, une minuscule, un caractère spécial et un chiffre`,
						}}
					/>
				</div>
				<div className="row">
					<FieldAdmin
						component="input"
						type="password"
						name="passwordConfirm"
						className="field"
						placeholder="Confirmation mot de passe"
						value={passwordConfirm}
						errorMessages={{
							const: () => 'Les deux mots de passe ne sont pas identiques',
						}}
					/>
				</div>
				{
					withCode
					&& (
						<div className="row">
							<FieldAdmin
								component="input"
								type="text"
								name="code"
								placeholder="Code"
								value={code}
							/>
						</div>
					)
				}
				<FormButton loading={loading} type="submit">Envoyer</FormButton>
				<AlertMessage
					display={display}
					title={title}
					message={message}
					success={success}
				/>
			</AuthForm>
		);
	}
}

AuthFormsResetPassword.propTypes = {
	history: PropTypes.shape({ push: PropTypes.func }),
	name: PropTypes.string,
	temporaryPassword: PropTypes.string,
	username: PropTypes.string,
	withCode: PropTypes.bool,
};

AuthFormsResetPassword.defaultProps = {
	history: {},
	name: '',
	temporaryPassword: '',
	username: '',
	withCode: false,
};

export default AuthFormsResetPassword;
