import S3 from 'aws-sdk/clients/s3';

import './aws';
import { fetchWithSession } from './session';

const {
	REACT_APP_API_URL,
	REACT_APP_AWS_S3_BUCKET_NAME,
} = process.env;

const s3 = new S3({
	apiVersion: '2006-03-01',
	params: { Bucket: REACT_APP_AWS_S3_BUCKET_NAME },
});

const getUploaderUrl = key => fetchWithSession(
	`${REACT_APP_API_URL}/promoteur/upload/file`,
	{
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'POST',
		body: JSON.stringify({ key }),
	},
)
	.then((response) => {
		if (!response.ok) {
			return Promise.reject(response.json());
		}

		return response.json();
	});

export const sendFile = (url, blob) => fetch(url, {
	headers: {
		'Content-Type': blob.type,
	},
	method: 'PUT',
	body: blob,
})
	.then((response) => {
		if (!response.ok) {
			return Promise.reject(response);
		}

		return response;
	});

export const uploadFile = (file) => {
	const filename = file.name;
	const key = filename;//`${filename}-${order}`;

	return getUploaderUrl(key)
		.then(result => sendFile(result.url, file));
};

export const removeFile = key => new Promise((resolve, reject) => {
	s3.deleteObject({
		Key: key,
	}, (err, data) => {
		if (err) {
			reject(err);
			return;
		}

		resolve(data);
	});
});
