
export const SESSION_CLEAR_SESSION_ACTION = 'SESSION_CLEAR_SESSION_ACTION';
export const SESSION_INVALIDATE_SESSION_ACTION = 'SESSION_INVALIDATE_SESSION_ACTION';
export const SESSION_SET_SESSION_ACTION = 'SESSION_SET_SESSION_ACTION';

export const clearSessionAction = () => ({
	type: SESSION_CLEAR_SESSION_ACTION,
});

export const invalidateSessionAction = () => ({
	type: SESSION_INVALIDATE_SESSION_ACTION,
});

export const setSessionAction = session => ({
	type: SESSION_SET_SESSION_ACTION,
	session,
});
