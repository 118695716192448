import React from 'react';
import PropTypes from 'prop-types';

import decoding from '@cecaz-immo/ui/dist/lib/decode';
import PageContent from '@cecaz-immo/ui/dist/components/page/content';

import AuthFormsResetPassword from '../../components/auth/forms/reset-password';

const ResetPassword = ({
	developer,
	history,
	lp,
	match,
	...rest
}) => {
	let name;
	let temporaryPassword;
	let username;
	const { credentials } = match.params;
	if (credentials) {
		const params = decoding(credentials);
		[username, temporaryPassword, name] = params;
	} else {
		({ username, temporaryPassword, name } = match.params);
	}

	return (
		<PageContent className="PageAuthResetPassword">
			<div className="section last-section">
				<header>
					<h1 className="section-title">
						{/* eslint-disable-next-line no-nested-ternary */}
						Espace { developer ? (lp ? 'Partenaire' : 'Promoteur') : 'Administrateur' }
					</h1>
				</header>
				<div className="page-center">
					<AuthFormsResetPassword
						history={history}
						name={name}
						temporaryPassword={temporaryPassword}
						username={username}
						{...rest}
					/>
				</div>
			</div>
		</PageContent>);
};

ResetPassword.propTypes = {
	developer: PropTypes.bool,
	history: PropTypes.shape({}).isRequired,
	lp: PropTypes.bool,
};

ResetPassword.defaultProps = {
	developer: false,
	lp: false,
};

export default ResetPassword;
