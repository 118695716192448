import React from 'react';
import PropTypes from 'prop-types';

import Field from './field';


import './admin.scss';

const FieldAdmin = ({
	className,
	...props
}) => (
	<Field
		className={`FieldAdmin ${className || ''}`}
		{...props}
	/>
);

FieldAdmin.propTypes = {
	className: PropTypes.string,
};

FieldAdmin.defaultProps = {
	className: '',
};

export default FieldAdmin;
