import PropTypes from 'prop-types';
import React from 'react';

import Form from '@cecaz-immo/ui/dist/components/form/form';
import FieldAdmin from '@cecaz-immo/ui/dist/components/form/field/admin';
import ButtonLoading from '@cecaz-immo/ui/dist/components/button/Loading';
import FormGroup from '@cecaz-immo/ui/dist/components/form/group/group';

import AlertMessage from '../../alert-message/alert-message';

// schemas
import developerSchema from '../../../schemas/developer';


class DeveloperEdit extends React.Component {
	constructor(props) {
		super(props);

		this.onSubmit = this.onSubmit.bind(this);
		this.onChange = this.onChange.bind(this);

		this.state = {
			alertMessage: {
				title: '',
				message: '',
				display: false,
				success: false,
			},
			loading: false,
		};
	}

	onChange(data) {
		const { updateDeveloper } = this.props;
		updateDeveloper(data);
	}

	onSubmit(e) {
		e.preventDefault();
		const { developer } = this.props;
		this.setState({ loading: true });
		const { lp, myAccount } = this.props;
		let entity;
		if (myAccount) {
			entity = 'Votre compte';
		} else if (lp) {
			entity = 'Le partenaire';
		} else {
			entity = 'Le promoteur';
		}

		const { saveDeveloper } = this.props;
		const action = developer.id ? 'modifié' : 'créé';

		saveDeveloper().then(() => {
			this.setState({
				loading: false,
				alertMessage: {
					display: true,
					title: 'Succès',
					message: `${entity} a été ${action} avec succès`,
					success: true,
				},
			});
		}).catch(() => {
			this.setState({
				loading: false,
				alertMessage: {
					display: true,
					title: 'Erreur',
					message: 'Une erreur technique est survenue, merci de réessayer ultérieurement',
					success: false,
				},
			});
		});
	}

	render() {
		const { developer } = this.props;
		const {
			alertMessage,
			loading,
		} = this.state;

		return (
			<Form
				id="developer-form"
				schema={developerSchema}
				data={developer}
				onChange={this.onChange}
				onSubmit={this.onSubmit}
			>
				<FormGroup>
					<label htmlFor="developer-firstName">Prénom</label>
					<FieldAdmin
						id="developer-firstName"
						component="input"
						name="firstName"
						type="text"
						placeholder="Prénom"
						value={developer.firstName || ''}
					/>
				</FormGroup>
				<FormGroup>
					<label htmlFor="developer-lastName">Nom</label>
					<FieldAdmin
						id="developer-lastName"
						component="input"
						name="lastName"
						type="text"
						placeholder="Nom"
						value={developer.lastName || ''}
					/>
				</FormGroup>
				<FormGroup>
					<label htmlFor="developer-tradingName">Nom commercial</label>
					<FieldAdmin
						id="developer-tradingName"
						component="input"
						name="tradingName"
						type="text"
						placeholder="Nom commercial"
						value={developer.tradingName || ''}
					/>
				</FormGroup>
				<FormGroup>
					<label htmlFor="developer-phone">Téléphone</label>
					<FieldAdmin
						id="developer-phone"
						component="input"
						name="phone"
						type="text"
						placeholder="Téléphone"
						value={developer.phone || ''}
						errorMessages={{
							minLength: () => 'Le numéro est non valide',
						}}
					/>
				</FormGroup>
				<FormGroup>
					<label htmlFor="developer-email">Email*</label>
					<FieldAdmin
						id="developer-email"
						component="input"
						name="email"
						type="text"
						placeholder="Email"
						disabled={!!developer.id}
						value={developer.email || ''}
					/>
				</FormGroup>
				<FormGroup>
					<ButtonLoading
						color="secondary"
						outline
						loading={loading}
						type="submit"
					>
						Valider
					</ButtonLoading>
				</FormGroup>
				<AlertMessage
					display={alertMessage.display}
					title={alertMessage.title}
					message={alertMessage.message}
					success={alertMessage.success}
				/>
			</Form>
		);
	}
}

DeveloperEdit.propTypes = {
	lp: PropTypes.bool,
	myAccount: PropTypes.bool,
	saveDeveloper: PropTypes.func.isRequired,
	updateDeveloper: PropTypes.func.isRequired,
	developer: PropTypes.shape({
		id: PropTypes.number,
		email: PropTypes.string,
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		tradingName: PropTypes.string,
		phone: PropTypes.string,
	}),
};

DeveloperEdit.defaultProps = {
	developer: {},
	lp: false,
	myAccount: false,
};

export default DeveloperEdit;
