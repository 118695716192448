
import { EDIT_PROGRAMME_DELETE_ACTION } from '../actions/edit-programme';
import { SET_CURRENT_DEVELOPER_ACTION } from '../actions/developer';
import { FINISHED } from '../../lib/status';

const programmes = (state = [], action) => {
	let newState = Object.assign([], state);
	switch (action.type) {
	case EDIT_PROGRAMME_DELETE_ACTION:
		newState = state.map((programme) => {
			if (programme.uuid !== action.programme.uuid) {
				return programme;
			}

			return {
				...programme,
				status: FINISHED,
			};
		});
		break;
	default:
		return state;
	}
	return newState;
};

const currentDeveloper = (state = {}, action) => {
	let newState = Object.assign({}, state);

	switch (action.type) {
	case EDIT_PROGRAMME_DELETE_ACTION:
		newState.programmes = programmes(state.programmes, action);
		break;
	case SET_CURRENT_DEVELOPER_ACTION:
		newState = Object.assign({}, state, action.developer);
		break;
	default:
		return state;
	}

	return newState;
};

export default currentDeveloper;
