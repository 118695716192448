import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import ProgrammeEditListItem from './item';

import './list.scss';

const ProgrammeEditList = ({
	className,
	displayEditButton,
	programmes,
	status,
}) => {
	const programmesFilteredByStatus = useMemo(() => programmes.filter(
		(p) => !status || status === p.status,
	), [status, programmes]);

	return (
		<ul className={`ProgrammeEditList ProgrammeList list-undecorated ${className}`}>
			{ programmesFilteredByStatus.length === 0 && <li className="no-result">Aucun résultat</li> }
			{
				programmesFilteredByStatus.map((programme, index) => (
					<li key={programme.id}>
						<ProgrammeEditListItem
							displayEditButton={displayEditButton}
							index={index}
							programme={programme}
						/>
					</li>
				))
			}
		</ul>
	);
};

ProgrammeEditList.propTypes = {
	className: PropTypes.string,
	displayEditButton: PropTypes.bool,
	programmes: PropTypes.arrayOf(PropTypes.shape({})),
	status: PropTypes.string,
};

ProgrammeEditList.defaultProps = {
	className: '',
	displayEditButton: false,
	programmes: null,
	status: null,
};

export default ProgrammeEditList;
