import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

export class WithSession extends React.Component {
	render() {
		const { Component, render, session } = this.props;

		const pending = session === null;
		if (pending) return null;

		const newProps = Object.assign({ session }, this.props);

		if (Component) return React.createElement(Component, newProps);
		if (render) return render(newProps);

		return null;
	}
}

WithSession.propTypes = {
	Component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	render: PropTypes.func,
	session: PropTypes.shape({}),
};

WithSession.defaultProps = {
	Component: null,
	render: null,
	session: null,
};

const mapStateToProps = state => ({
	session: state.session,
});

const WithSessionContainer = connect(mapStateToProps)(WithSession);

export default WithSessionContainer;

export const withSession = Component => (
	props => <WithSessionContainer Component={Component} {...props} />
);
