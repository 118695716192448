import PropTypes from 'prop-types';
import React from 'react';

import Form from '@cecaz-immo/ui/dist/components/form/form';


import FieldAdmin from '@cecaz-immo/ui/dist/components/form/field/admin';
import ButtonLoading from '@cecaz-immo/ui/dist/components/button/Loading';
import FormGroup from '@cecaz-immo/ui/dist/components/form/group/group';
import userSchema from '../../../schemas/user';
import AlertMessage from '../../alert-message/alert-message';
import { changePassword } from '../../../lib/session';

class Password extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			alertMessage: {
				title: '',
				message: '',
				display: false,
				success: false,
			},
			data: {},
			loading: false,
		};

		this.onSubmit = this.onSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}


	onSubmit(e) {
		e.preventDefault();
		this.setState({ loading: true });

		const { user } = this.props;
		const { data } = this.state;

		changePassword(user.email, data.oldPassword, data.password).then(() => {
			this.setState({
				loading: false,
				alertMessage: {
					display: true,
					title: 'Succès',
					message: 'Votre mot de passe a été mis à jour !',
					success: true,
				},
				data: {},
			});
		}).catch((err) => {
			let title = '';
			let message = '';

			if (err.code === 'NotAuthorizedException') {
				title = 'Erreur mot de passe';
				message = 'Vous avez saisi un mauvais mot de passe...';
			} else {
				title = 'Une erreur est survenue...';
				message = 'Contactez votre administrateur pour plus de renseignement !';
			}

			this.setState({
				loading: false,
				alertMessage: {
					display: true,
					title,
					message,
					success: false,
				},
				data: {},
			});
		});
	}

	handleChange(data) {
		this.setState({ data });
	}

	render() {
		const {
			alertMessage,
			data,
			loading,
		} = this.state;

		return (
			<Form
				id="user-form"
				schema={userSchema}
				data={data}
				onSubmit={this.onSubmit}
				onChange={this.handleChange}
			>
				<FormGroup>
					<label htmlFor="user-oldPassword">Ancien mot de passe</label>
					<FieldAdmin
						id="user-oldPassword"
						component="input"
						name="oldPassword"
						type="password"
						placeholder="Mot de passe"
						value={data.oldPassword || ''}
					/>
				</FormGroup>
				<p>
					Le mot de passe doit contenir :
					au minimum 8 caractères dont: une
					<strong> majuscule</strong>, une<strong> minuscule</strong>, un
					<strong> caractère spécial</strong> et un <strong>chiffre</strong>.
				</p>
				<FormGroup>
					<label htmlFor="user-password">Nouveau mot de passe</label>
					<FieldAdmin
						id="user-password"
						component="input"
						name="password"
						type="password"
						placeholder="Nouveau mot de passe"
						value={data.password || ''}
						errorMessages={{
							pattern: () => `Le mot de passe doit contenir :au minimum 8 caractères dont: une
majuscule, une minuscule, un caractère spécial et un chiffre`,
						}}
					/>
				</FormGroup>
				<FormGroup>
					<label htmlFor="user-passwordConfirm">Confirmation mot de passe</label>
					<FieldAdmin
						id="user-passwordConfirm"
						component="input"
						name="passwordConfirm"
						type="password"
						placeholder="Confirmer le mot de passe"
						value={data.passwordConfirm || ''}
						errorMessages={{
							const: () => 'Les deux mots de passe ne sont pas identiques',
						}}
					/>
				</FormGroup>
				<FormGroup>
					<ButtonLoading
						color="secondary"
						outline
						loading={loading}
						type="submit"
					>
						Valider
					</ButtonLoading>
				</FormGroup>
				<AlertMessage
					display={alertMessage.display}
					title={alertMessage.title}
					message={alertMessage.message}
					success={alertMessage.success}
				/>
			</Form>
		);
	}
}

Password.propTypes = {
	user: PropTypes.shape({
		email: PropTypes.string,
	}),
};

Password.defaultProps = {
	user: null,
};

export default Password;
