import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
	Card, CardBody, CardHeader, Col,
} from 'reactstrap';
import clsx from 'clsx';
import Dropzone from 'react-dropzone';
import Dragula from 'react-dragula';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FieldError } from 'react-jsonschema-form-validation';

import { read, resize } from '@cecaz-immo/ui/dist/lib/image-resizer';

import ProgrammeEditTabsMediaItem from './media-item';
import UploadFiles from './upload-files/upload-files';

import './medias.scss';

const ProgrammeEditMedias = ({ className, programme, updateFiles }) => {
	const [files, setFiles] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setFiles(programme.files || []);
	}, [programme]);

	const onDrop = (acceptedFiles) => {
		const resizedFiles = [];
		setLoading(true);
		acceptedFiles.forEach((f, i) => {
			f.order = files.length + i;
		});
		const promise = acceptedFiles.reduce(async (accumulator, file) => {
			const acc = await accumulator;

			const arrayBuffer = await read(file);
			const buffer = await resize(arrayBuffer, { buffer: true, height: 1048, width: 1920 });
			const media = {
				blob: new Blob([buffer], { type: file.type }),
				mimeType: file.type,
				name: file.name,
				buffer: arrayBuffer,
				filename: file.name,
				order: file.order,
				type: file.type,
				preview: URL.createObjectURL(file),
			};
			resizedFiles.push(media);
			return Promise.resolve([...acc, media]);
		}, Promise.resolve([]));

		promise.then(() => {
			updateFiles(files.concat(resizedFiles));
		}).catch((err) => {
			// eslint-disable-next-line no-console
			console.error(err);
			global.rollbar.error('onDrop', err);
		}).finally(() => {
			setLoading(false);
		});
	};

	const onClickDelete = (index) => {
		const newFiles = [].concat(files);
		newFiles.splice(index, 1);
		for (let i = index; i < newFiles.length; i += 1) {
			newFiles[i].order -= 1;
		}
		updateFiles(newFiles);
		setFiles(newFiles || []);
	};

	const onDragEnd = (el, target) => {
		const newFiles = [].concat(files);

		const list = target.querySelectorAll('li');
		list.forEach((li, order) => {
			for (let i = 0; i < newFiles.length; i += 1) {
				const f = newFiles[i];
				if (f.name === li.dataset.name) {
					f.order = order;
					break;
				}
			}
		});
		return false;
	};

	const dragulaDecorator = (componentBackingInstance) => {
		if (componentBackingInstance) {
			const options = {
				direction: 'horizontal',
				accepts: (el, target, source, sibling) => {
					const filename = el.dataset.name;
					const filenameParts = filename.split('.');
					const extension = filenameParts[filenameParts.length - 1];
					if (extension !== 'mp4') return true;
					if (sibling === target.querySelectorAll('li')[0]) return false;
					return true;
				},
			};
			const drake = Dragula([componentBackingInstance], options);
			drake.on('drop', onDragEnd);
		}
	};

	return (
		<Card className={clsx(className, 'ProgrammeEditMedias')}>
			<CardHeader>
				<h2>Médias</h2>
			</CardHeader>
			<CardBody>

				<Col>
					<FieldError
						className="my-2"
						name="files"
						errorMessages={{
							required: () => 'Merci d\'ajouter au moins une image',
							minItems: () => 'Merci d\'ajouter au moins une image',
						}}
					/>

					<Dropzone
						onDrop={onDrop}
						accept="image/jpg, image/jpeg, image/png, video/mp4"
						className="ProgrammeEditMedias-dropzone"
						activeClassName="dropzone-active"
						rejectClassName="dropzone-reject"
					>
						{({ getRootProps, getInputProps }) => (
							<div {...getRootProps({
								className: 'ProgrammeEditMedias-dropzone',
							})}
							>
								<ul
									className="list-undecorated ProgrammeEditMedias-list"
									ref={dragulaDecorator}
									onClick={(e) => { e.stopPropagation(); }}
								>
									{
										files.map((file, i) => (
											<li key={file.name} data-name={file.name}>
												<ProgrammeEditTabsMediaItem
													title="Principale"
													file={file}
													onClickDelete={() => { onClickDelete(i); }}
												/>
											</li>
										))
									}
								</ul>

								<input {...getInputProps()} />
								<UploadFiles loading={loading} />
								<div className="drop-overlay">
									<strong className="drop-overlay-reject">Type de fichier invalide</strong>
									<strong className="drop-overlay-active">Ajouter le fichier</strong>
								</div>
							</div>
						)}
					</Dropzone>
				</Col>
			</CardBody>
		</Card>
	);
};

ProgrammeEditMedias.propTypes = {
	className: PropTypes.string,
	programme: PropTypes.shape({ files: PropTypes.arrayOf(PropTypes.object) }),
	updateFiles: PropTypes.func.isRequired,
};

ProgrammeEditMedias.defaultProps = {
	className: '',
	programme: {},
};

export default ProgrammeEditMedias;
