import React from 'react';

import './actions.scss';

export default ({ children }) => (
    <header className="section Actions">
        <div className="page-center">
            { children }
        </div>
    </header>
);