import {
	SET_ADMIN_ACTION,
} from '../actions/admin';

const admin = (state = {}, action) => {
	let newState = Object.assign({}, state);
	switch (action.type) {
	case SET_ADMIN_ACTION:
		newState = action.admin;
		break;
	default:
		return state;
	}

	return newState;
};

export default admin;
