import PropTypes from 'prop-types';
import React from 'react';

const Button = ({
	children,
	className,
	loading,
	type,
	...rest
}) => (
	// eslint-disable-next-line react/button-has-type
	<button
		className={`FormButton btn btn-red ${className}`}
		disabled={loading}
		type={type}
		{...rest}
	>
		{
			loading
				? <i className="fa fa-spinner spinner" />
				: children
		}
	</button>
);

Button.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	loading: PropTypes.bool,
	type: PropTypes.string,
};

Button.defaultProps = {
	className: '',
	loading: false,
	type: 'button',
};

export default Button;
