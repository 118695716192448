import { transformProgramme } from '@cecaz-immo/app-client/dist/store/actions/programme';

import { fetchWithSession } from '../../lib/session';

const { REACT_APP_API_URL } = process.env;

export const SET_CURRENT_DEVELOPER_ACTION = 'SET_CURRENT_DEVELOPER_ACTION';

const setCurrentDeveloperAction = (developer) => ({
	type: SET_CURRENT_DEVELOPER_ACTION,
	developer,
});

export const getCurrentDeveloperAction = () => (dispatch) => fetchWithSession(
	`${REACT_APP_API_URL}/promoteur`,
	{
		headers: {
			'Cache-Control': 'no-cache',
		},
	},
)
	.then((resp) => resp.json())
	.then((result) => {
		result.programmes.forEach((item) => {
			transformProgramme(item);
		});
		dispatch(setCurrentDeveloperAction(result));
	});
