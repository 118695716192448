import React from 'react';
import PropTypes from 'prop-types';

import './alert-message.scss';

const AlertMessage = ({
	className,
	display,
	message,
	success,
	title,
}) => (
	display
		? (
			<section className={`AlertMessage ${className || ''}`}>
				<div className="AlertMessage-content">
					<header className={`header ${(success && 'success') || ''}`}>{title}</header>
					<p className="content">{message}</p>
				</div>
			</section>
		)
		: null
);

AlertMessage.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	display: PropTypes.bool,
	success: PropTypes.bool,
};

AlertMessage.defaultProps = {
	className: '',
	display: false,
	success: false,
};

export default AlertMessage;
