import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

import defaultLotValue from './default-lot-value';

const ProgrammeEditTabsAddLotItem = ({ createLotProgramme }) => {
	const handleClickAdd = () => {
		const newLot = { ...defaultLotValue };
		createLotProgramme(newLot);
	};

	return (
		<Button
			className="btn btn-icon field AddItems"
			onClick={handleClickAdd}
			outline
			title="Ajouter un lot"
			type="button"
		>
			Ajouter un lot
			<i className="fa fa-plus center field icon ml-2" />
		</Button>
	);
};

ProgrammeEditTabsAddLotItem.propTypes = {
	createLotProgramme: PropTypes.func.isRequired,
};

export default ProgrammeEditTabsAddLotItem;
