// eslint-disable-next-line import/no-extraneous-dependencies
import { FieldError } from 'react-jsonschema-form-validation';
import React from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Row,
} from 'reactstrap';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import ProgrammeEditTabsLotItem from './lot-item';
import ProgrammeEditTabsAddLotItem from './add-items';

import './lots.scss';

const ProgrammeEditLots = ({
	className,
	createLotProgramme,
	deleteLotProgramme,
	lots,
	updateLotProgramme,
}) => (
	<Card className={clsx(className, 'ProgrammeEditLots')}>
		<CardHeader>
			<h2>Lots</h2>
		</CardHeader>
		<CardBody>
			<Row>
				<Col xs="12">
					<FieldError
						name="lots"
						errorMessages={{
							required: () => 'Merci d\'ajouter au moins un lot',
							minItems: () => 'Merci d\'ajouter au moins un lot',
						}}
					/>
					<ul className="ProgrammeEditLots-list list-undecorated">
						{
							lots && lots.map((item, i) => (
								<li key={parseInt(i.toString(), 10)} className="lot">
									<ProgrammeEditTabsLotItem
										lot={item}
										index={i}
										deleteLotProgramme={deleteLotProgramme}
										createLotProgramme={createLotProgramme}
										updateLotProgramme={updateLotProgramme}
									/>
								</li>
							))
						}

						<li className="lot">
							<ProgrammeEditTabsAddLotItem
								createLotProgramme={createLotProgramme}
							/>
						</li>
					</ul>
				</Col>
			</Row>
		</CardBody>
	</Card>
);

ProgrammeEditLots.propTypes = {
	className: PropTypes.string,
	createLotProgramme: PropTypes.func.isRequired,
	deleteLotProgramme: PropTypes.func.isRequired,
	updateLotProgramme: PropTypes.func.isRequired,
	lots: PropTypes.arrayOf(PropTypes.object),
};

ProgrammeEditLots.defaultProps = {
	className: '',
	lots: [],
};

export default ProgrammeEditLots;
