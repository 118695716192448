export default {
	type: 'FLAT',
	rooms: 1,
	minArea: '',
	maxArea: '',
	minPrice: '',
	maxPrice: '',
	amount: '',
	containParking: false,
};
