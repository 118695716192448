const toInputDate = (date, opt) => {
	if (!date) return date;
	// IE11 welcome to hell
	// https://stackoverflow.com/questions/36225603/internet-explorer-returning-wrong-length-of-string
	return `${new Date(date).toLocaleDateString('fr-FR', opt).replace(/[^ -~]/g, '')}`;
};

const toInputDateTime = (date) => toInputDate(date, { hour: '2-digit', minute: '2-digit' });


const toISO = (date) => {
	if (!date) return date;
	const arr = date.split('/');
	const englishDate = `${arr[1]}/${arr[0]}/${arr[2]}`;
	return new Date(englishDate).toISOString();
};

const formatTodayDate = () => {
	const myDate = new Date();
	const date = myDate.getDate();
	const month = myDate.getMonth();
	const year = myDate.getFullYear();

	function pad(n) {
		return n < 10 ? `0${n}` : n;
	}

	return `${pad(date)}-${pad(month + 1)}-${year}`;
};

exports.formatTodayDate = formatTodayDate;
exports.toInputDate = toInputDate;
exports.toInputDateTime = toInputDateTime;
exports.toISO = toISO;
