import {
	EDIT_PROGRAMME_CREATE_ACTION,
	EDIT_PROGRAMME_UPDATE_ACTION,
	EDIT_PROGRAMME_FILES_UPDATE_ACTION,
	EDIT_PROGRAMME_LOT_UPDATE_ACTION,
	EDIT_PROGRAMME_LOT_CREATE_ACTION,
	EDIT_PROGRAMME_LOT_DELETE_ACTION,
} from '../actions/edit-programme';

const lots = (state = [], action) => {
	const newState = [...state];

	switch (action.type) {
	case EDIT_PROGRAMME_LOT_UPDATE_ACTION:
		newState[action.index] = { ...newState[action.index], ...action.lot };
		break;
	case EDIT_PROGRAMME_LOT_CREATE_ACTION:
		delete action.lot.id;
		newState.push({ ...action.lot });
		break;
	case EDIT_PROGRAMME_LOT_DELETE_ACTION:
		newState.splice(action.index, 1);
		break;
	default:
		return state;
	}

	return newState;
};

const defaultState = {
	tags: [],
};

export default (state = defaultState, action) => {
	let newState;
	switch (action.type) {
	case EDIT_PROGRAMME_CREATE_ACTION:
		return defaultState;
	case EDIT_PROGRAMME_UPDATE_ACTION:
		newState = { ...state, ...action.programme };
		return newState;
	case EDIT_PROGRAMME_LOT_UPDATE_ACTION:
	case EDIT_PROGRAMME_LOT_CREATE_ACTION:
	case EDIT_PROGRAMME_LOT_DELETE_ACTION:
		newState = { ...state };
		newState.lots = lots(state.lots, action);
		return newState;
	case EDIT_PROGRAMME_FILES_UPDATE_ACTION:
		newState = { ...state };
		newState.files = action.files;
		return newState;
	default:
		return state;
	}
};
