import { fetchWithSession } from '../../lib/session';

const { REACT_APP_API_URL } = process.env;

export const SET_ADMIN_ACTION = 'SET_ADMIN_ACTION';

const setAdminAction = admin => ({
	type: SET_ADMIN_ACTION,
	admin,
});

export const getAdminByIdAction = adminId => dispatch => fetchWithSession(
	`${REACT_APP_API_URL}/admin/${adminId}`,
	{
		headers: {
			'Cache-Control': 'no-cache',
		},
	},
)
	.then(resp => resp.json())
	.then((result) => {
		dispatch(setAdminAction(result));
	});
