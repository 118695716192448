import memoize from 'memoize-one';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Select from './Select';

class FormSelectSimple extends PureComponent {
	constructor(props) {
		super(props);

		this.getOptionValue = memoize((options, values, getOptionValue, isMulti) => {
			getOptionValue = getOptionValue || (option => option.value);
			const comparator = value => option => getOptionValue(option) === value;

			return (values !== null && values !== undefined)
				&& (
					isMulti
						? values.map(value => options.find(comparator(value)))
						: options.find(comparator(values))
				);
		});
	}

	render() {
		const {
			getOptionValue,
			isMulti,
			options,
			value,
			...props
		} = this.props;

		const optionValue = this.getOptionValue(options, value, getOptionValue, isMulti);
		return (
			<Select
				getOptionValue={getOptionValue}
				isMulti={isMulti}
				options={options}
				value={optionValue}
				{...props}
			/>
		);
	}
}

FormSelectSimple.propTypes = {
	getOptionValue: PropTypes.func,
	isMulti: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.shape({})),
	value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

FormSelectSimple.defaultProps = {
	getOptionValue: undefined,
	isMulti: false,
	options: [],
	value: undefined,
};

export default FormSelectSimple;
