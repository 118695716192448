import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button } from 'reactstrap';


const ButtonLoading = ({
	children,
	className,
	loading,
	...props
}) => (
	<Button
		className={clsx({ 'justify-content-center': loading }, className)}
		disabled={loading}
		{...props}
	>
		{loading ? (
			<>
				<i className="fa fa-spinner spinner mr-2" aria-hidden="true" role="status" />
				<span>Chargement...</span>
			</>
		) : children}
	</Button>
);

ButtonLoading.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	loading: PropTypes.bool,
};

ButtonLoading.defaultProps = {
	children: null,
	className: '',
	loading: false,
};

export default React.memo(ButtonLoading);
