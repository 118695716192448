import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import './validation-info.scss';

class ProgrammeValidationInfo extends PureComponent {
	render() {
		const {
			commentary,
			linkToEdit,
			uuid,
		} = this.props;

		return (
			<section className="ProgrammeValidationInfo">
				<p className="ProgrammeValidation-message-admin">
					Cette version de votre bien a été refusée par
					votre administrateur pour les raisons suivantes :
				</p>
				<textarea className="ProgrammeValidation-message" value={commentary} readOnly />
				{
					linkToEdit
					&& (
						<div className="ProgrammeValidation-submit">
							<Link to={`/programme/edit/${uuid}`} className="btn btn-red">Modifier le bien</Link>
						</div>
					)
				}
			</section>
		);
	}
}

ProgrammeValidationInfo.propTypes = {
	commentary: PropTypes.string.isRequired,
	linkToEdit: PropTypes.bool,
	uuid: PropTypes.string.isRequired,
};
ProgrammeValidationInfo.defaultProps = {
	linkToEdit: false,
};

export default ProgrammeValidationInfo;
