import React from 'react';

import Legal from '@cecaz-immo/app-client/dist/components/legal/mentions';
import PageContent from '@cecaz-immo/ui/dist/components/page/content';


const {
	REACT_APP_EMAIL_PUBLIC,
	REACT_APP_WWW,
} = process.env;

const PageLegal = () => (
	<PageContent className="PageLegal">
		<section className="page-center section last-section">
			<Legal appLegalEmail={REACT_APP_EMAIL_PUBLIC} appWWW={REACT_APP_WWW} />
		</section>
	</PageContent>
);

export default PageLegal;
